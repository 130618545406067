@import 'styles/app';

.splitButton {
  display: inline-flex;
}

.splitButtonSmall {
  height: 34px;
}

.dropdown {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid;
  border-left: 0px;
  font-size: $small-button-font;

  & > * {
    height: 100%;
    padding: 0 8px;
    place-items: center;
    display: grid;
  }

  &:hover {
    background-color: $color-neutral-100;
  }
}

.highlight {
  @include highlight-button-preset();
}

.danger {
  @include danger-button-preset();
}

.disabled {
  border-color: $color-neutral-500;
  opacity: 30%;

  &:hover {
    background-color: #fff;
  }

  > .icon > path {
    fill: $color-neutral-500;
  }
}

.borderlessRightButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
