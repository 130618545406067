@import 'styles/app';

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.fullView {
  @include hide-on-print;
}

@media print {
  .card {
    box-shadow: none;
    border: 1px solid rgba($color-neutral-600, 0.1);
  }
}
