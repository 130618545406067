@import 'styles/app';

.container {
  display: flex;
  justify-content: space-between;
}

.input {
  display: none;
}

.dataImportButton {
  padding: 8px 0;
}

.dataImportButtonWithStatus {
  margin-bottom: 32px;
}

.trigger {
  cursor: pointer;
  font-weight: 600;
}

.dataImportStatus {
  position: absolute;
  bottom: 32px;
  right: 0;
  width: 520px;
  transform: translateY(calc(100% + 16px));
  text-align: right;

  & > * {
    text-align: left;
  }
}
