@import 'styles/app';

.badgeColl {
  padding: 8px 4px !important;
  min-height: 48px !important;
}

.descriptionCol {
  max-width: 164px;
}

.groupingAssetArrow {
  fill: $color-neutral-600;
  display: inline-block;
  margin-right: 8px;
}

.groupingAssetArrowDown {
  transform: rotate(90deg);
}

.groupedAsset {
  background-color: $color-neutral-50;
}

.groupedAssetClosed {
  height: 0;
}
