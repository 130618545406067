@import 'styles/app';

.cardTitle {
  @include typography-label;
  color: $color-neutral-600;
  margin-bottom: 8px;
}

.card {
  background: $color-neutral-50;
  box-shadow: none;
}

.subtitle {
  @include typography-label;
  color: $color-neutral-700;
}
