@import 'styles/app';

.container + .container {
  margin-top: 8px;
}

.message {
  text-align: right;
  margin-top: 8px;
}

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label,
.label:before,
.message {
  @include typography-label;
  color: $color-neutral-600;
}

.label:before {
  content: '';
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: $color-neutral-400;
  border-radius: 12px;
  box-sizing: border-box;
  line-height: 13px;
  display: inline-block;
  cursor: pointer;
  width: 16px;
  height: 16px;
  text-align: center;
  margin-right: 8px;
  color: #fff;
}

.input {
  display: none;
}

.label.labelChecked:before {
  background-color: $color-accent-500;
  border-color: $color-accent-500;
  content: url("data:image/svg+xml;utf8,<svg width='6' height='14' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='3' cy='3' r='3' fill='white'/></svg>");
}

.label.labelDisabled:before {
  background-color: rgba($color-neutral-500, 0.3);
  border-color: rgba($color-neutral-500, 0.3);
  cursor: initial;
}

.labelError:before {
  border-color: $color-negative-600;
}

.labelError,
.messageError {
  color: $color-negative-600;
}
