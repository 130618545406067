@import 'styles/app';

.notificationContent {
  @include elevation-deep($color-neutral-900);

  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 24px;
  text-align: left;
  padding: 16px;
  width: 448px;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $color-neutral-900;
}

.button {
  margin-top: 16px;
}

.emptyState {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-600;
  margin: 24px 0;
}

.pagination {
  margin-top: 24px;
}
