@import 'styles/app';

.wrapper {
  margin-top: 32px;

  > * + * {
    margin-top: 16px;
  }
}

.title {
  @include typography-label;
  color: $color-neutral-600;
}

.addLink {
  display: block;
}

.card {
  margin-top: 8px;
}
