.accordionTitle {
  opacity: 0.6;
}

.columnsSize {
  display: grid;
  grid-template-columns: 1fr 250px;
}

.permissionCount {
  font-weight: bold;
}

.warningIcon {
  width: 12px;
  height: 12px;
  margin-left: 4px;
}
