@import 'styles/app';

.label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: $color-neutral-700;
  margin-bottom: 8px;
}

.relationship {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 8px;
  color: $color-neutral-700;
  font-size: 14px;
}

.badge {
  padding-block: 7px;
  border: 1px solid $color-neutral-200;
  background: transparent;
  font-size: 14px;
}
