@import 'styles/app';

.badge {
  border-radius: 2px;
  background-color: $color-neutral-100;
  color: $color-neutral-800;
  padding: 4px 8px;
  line-height: 16px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1em;
  width: auto;
  text-align: center;
}

.rounded {
  composes: badge;
  border-radius: 8px;
  font-size: 11px;
  color: $color-neutral-700;
  display: inline;
  font-weight: 600;
}

@media print {
  .badge {
    background-color: #fff;
    border: 1px solid $color-neutral-100;
    box-sizing: border-box;
    border-radius: 4px;
  }
}
