.lawsuitNumberInputWrapper {
  display: flex;
  gap: 8px;
}

.lawsuitNumberInput {
  flex-grow: 1;
}

.searchButtonWrapper {
  margin-top: 28px;
}
