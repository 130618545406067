@import 'styles/app';

.executionsLabel {
  @include typography-label;
  color: $color-neutral-700;
  font-size: 16px;
}

.emptyParagraph {
  @include typography-label;
  color: $color-neutral-600;
  font-weight: 400;
}

.buttons {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  gap: 16px;
}

.comments {
  margin-top: 32px;
}
