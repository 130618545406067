@import 'styles/app';
.lawsuits-reclaimings-list {
  margin-top: 32px;

  > .title {
    @include typography-label;
    color: $color-neutral-600;
  }

  > .list-item + .list-item,
  > * + * {
    margin-top: 8px;
  }
}
