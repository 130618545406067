@import 'styles/app';

.file-item {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 5px;
  position: relative;

  & > .info {
    margin-left: 8px;
    flex-grow: 1;
    height: 100%;

    & > span {
      display: block;
    }

    & > .name {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.05em;
      color: $color-neutral-700;

      overflow: hidden;
      overflow-wrap: anywhere;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;

      &.-deleting {
        color: $color-negative-600;
      }
    }

    & > .status {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: rgba($color-neutral-900, 0.8);

      &.-error {
        color: $color-negative-600;
      }
    }
  }

  & > .actions {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 48px;
    margin-left: 16px;
    z-index: 1;
  }

  & > .progress-background {
    height: 40px;
    position: absolute;
    left: 0;
    background-color: rgba($color-accent-500, 0.1);
  }

  & + .file-item {
    margin-top: 16px;
  }
}
