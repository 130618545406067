@import 'styles/app';

.maps {
  @include container-width($fullWidth: true);
  margin-top: 16px;
  position: relative;
}

.mapOverlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $color-neutral-500;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapsWrapper {
  margin-top: 32px;
}

.mapsTitle {
  color: $color-neutral-900;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-right: 12px;
}

.mapsTitleWrapper {
  @include container-width;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.mapsEmpty {
  @include container-width;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: $color-neutral-600;
  line-height: 24px;
  margin-top: 16px;
}

.providerTooltipWrapper {
  display: flex;
  align-items: center;
}

.providerTooltip {
  text-align: left;
}
