@import 'styles/app';

.label + .fields {
  margin-top: 8px;
}

.message {
  text-align: right;
  margin-top: 8px;
}

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label,
.label:before,
.message {
  @include typography-label;
  color: $color-neutral-600;
}

.labelError:before {
  border-color: $color-negative-600;
}
.labelError,
.messageError {
  color: $color-negative-600;
}
