.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  line-height: 16px;

  & > * + * {
    margin-left: 16px;
  }
}
