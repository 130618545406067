@import 'styles/app';

.boxMessage {
  @include elevation-shallow($color-neutral-900);

  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-family: $font-default;
  font-size: 12px;
  justify-content: center;
  line-height: 16px;
  padding: 8px;
  background-color: #fff;
}

.icon {
  margin-right: 8px;
}

.success {
  color: $color-positive-600;
}

.failure {
  color: $color-negative-600;
}

.loading {
  color: $color-accent-600;
}
