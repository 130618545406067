@import 'styles/app';

.label {
  @include typography-card-sub-title;
  margin-bottom: 8px;
}

.invoiceLabel {
  @include typography-card-sub-title;
  margin-top: 16px;
}

.submitButton {
  position: absolute;
  right: 16px;
  top: 16px;
}

.card {
  padding: 24px 16px;
  position: relative;
}
