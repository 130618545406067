@import 'styles/app';

.descriptionCol {
  max-width: 164px;
}

.column {
  background-color: transparent;
}

.nameType {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.assetType {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
}

.assetGroup {
  background: $color-neutral-50;
}

.indentCol {
  width: 37px;
  background: $color-neutral-50;
}

.header {
  background-color: $color-neutral-50;
}

@media print {
  .assetGroup {
    background: #fff;
  }

  .indentCol {
    background: #fff;
  }
}
