@import 'styles/app';

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.cardTitle {
  @include typography-card-sub-title;
  color: $color-neutral-700;
}

.cardsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.card {
  align-self: start;
}

.causeWrapper {
  display: flex;
  justify-content: space-between;
}

.causeValue,
.causeDate {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  color: $color-neutral-900;
}

.involvedParties {
  @include typography-label;
  color: $color-neutral-700;
}

.party {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: $color-neutral-600;
}

.bold {
  font-weight: 600;
}

@media print {
  .cardsWrapper {
    display: table;
  }

  .card {
    border: 1px solid $color-neutral-200;
    box-shadow: none;
    break-inside: avoid;
    width: calc(50% - 4px);
    display: inline-block;
    vertical-align: top;
    margin-bottom: 8px;
  }

  .card:nth-child(2n) {
    margin-left: 8px;
  }

  .causeWrapper {
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
  }

  .lawsuitWithError {
    @include hide-on-print;
  }
}
