@import 'styles/app';

.pin {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $color-accent-500;
  cursor: pointer;
}

.container {
  cursor: pointer;
  text-align: center;
}

.content {
  margin: 3px -1px;
  white-space: nowrap;
}
