@import 'styles/app';

.summary {
  @include elevation-shallow($color-neutral-900);
  margin-left: 20px;
  padding-left: 16px;
  padding-right: 10px;
  border-radius: 4px;
  font-family: $font-default;
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.success {
  color: $color-positive-700;
}

.failure {
  color: $color-negative-600;
}

.openErrors {
  color: $color-negative-600;
  font-family: $font-default;
  font-size: 12px;
  font-weight: 400;
}

.clearMessage {
  margin-left: 10px;
}
