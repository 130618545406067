@import 'styles/app';

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.debtorName {
  @include typography-card-sub-title;

  margin-right: 4px;
}

.judicialRecoveryBasicInfo {
  @include typography-label;

  display: flex;
  align-items: center;
  gap: 32px;

  color: $color-neutral-700;
}

.classesCard {
  color: $color-neutral-900;
}

.classesCardTitle {
  @include typography-card-sub-title;

  color: $color-neutral-900;
}

.classesCardDetailsWrapper {
  display: flex;

  gap: 12px 32px;
  flex-wrap: wrap;
}

.classesCardDetails {
  display: flex;
  align-items: center;
}

.classesCardLabel {
  @include typography-label;

  color: $color-neutral-700;
  margin-right: 8px;
}

.card + .card {
  margin-top: 16px;
}

@media print {
  .card,
  .classesCard {
    box-shadow: none;
    border: 1px solid rgba($color-neutral-600, 0.1);
  }
}
