@import 'styles/app';

.search {
  display: flex;
  align-items: center;
  gap: 8px;
}

.searchInput {
  flex-basis: 100%;
}

.searchButton {
  align-self: flex-end;
  margin-bottom: 2px;
}

.filters {
  padding: 16px;
  background-color: $color-neutral-50;
}

.filtersActions {
  display: flex;
  justify-content: center;
  gap: 16px;
}
