@import 'styles/app';

.title {
  @include typography-card-sub-title;
  color: $color-neutral-700;
}

.label {
  @include typography-label;
  margin-top: 24px;
  display: block;
  color: $color-neutral-600;
}
