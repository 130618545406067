@import 'styles/app';

.header {
  border-bottom: solid 1px $color-neutral-200;
  padding-bottom: 8px;
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.subtitle {
  margin-top: 8px;
}

.list {
  margin-top: 0;
}

.row {
  display: flex;
  padding: 16px 0;
}

.row + .row {
  border-top: solid 1px $color-neutral-200;
}

.rowLabel,
.rowValue,
.listValue {
  font: normal 600 12px/16px $font-default;
  letter-spacing: 0.05em;
  color: $color-neutral-700;
}

.rowLabel {
  flex-basis: 25%;
}

.rowValue {
  display: flex;
  flex: 1;
  margin: 0 8px;
}

.faded {
  color: $color-neutral-400;
  font-weight: 600;
}

.itemAlert {
  margin-left: 8px;
}

.list .row :global(.link-default[href]) {
  color: $color-accent-600;
  text-decoration: underline;
}

@media print {
  .list .row :global(.link-default[href]) {
    display: inline;
    color: $color-neutral-700;
    text-decoration: none;
  }
}
