@import 'styles/app';

.table-default {
  width: 100%;
  background-color: transparent;
  font-family: $font-default;

  .header {
    color: $color-neutral-400;
    font-size: 12px;
    font-weight: bold;

    td {
      font-weight: bold;
      line-height: 16px;
      padding: 16px 8px;

      &.-sticky {
        position: sticky;
        top: 0;
        border-bottom: 1px solid $color-neutral-200;
        background: white;

        &:last-child::after,
        &:first-child::before {
          width: $container-padding-size;
          top: 0;
          position: absolute;
          background: white;
          border-bottom: 1px solid $color-neutral-200;
          content: '';
          height: 100%;
        }
        &:first-child::before {
          left: 0;
          transform: translateX(-100%);
        }

        &:last-child::after {
          right: 0;
          transform: translateX(100%);
        }
      }
    }
  }

  .body {
    font-size: 14px;
    color: $color-neutral-700;

    .row:hover {
      background-color: $color-accent-50;

      &.-clickable {
        cursor: pointer;
      }
    }

    .cell {
      border-top: 1px solid $color-neutral-200;
      padding: 16px 8px;
      text-align: left;
      vertical-align: top;
      line-height: 16px;

      & .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        line-height: 16px;

        & > * + * {
          margin-left: 16px;
        }
      }
    }
  }

  .header,
  .body {
    .cell {
      &.-right {
        text-align: right;
      }

      &.-center {
        text-align: center;
      }

      &.-centeredContent {
        vertical-align: middle;
      }
    }
    tr {
      &.-small > td {
        padding: 8px;
      }
    }
  }
}
