@import 'styles/app';

.pin {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: $color-punch-600;
  cursor: pointer;
}

.titleBold {
  font-weight: 900;
  margin-right: 8px;
}
