@import 'styles/app';

.modalContent {
  @include typography-normal;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: $color-neutral-600;
}

.loaderLabel {
  margin-top: 12px;
}
