@import 'styles/app';

@mixin font {
  font-family: Inter;
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-600;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  > :global(.select) {
    width: 120px;
    margin-left: 16px;
  }
}

.itemsCounter {
  @include font;
  text-align: center;
  margin: 0px;
}

.inputLabel {
  @include font;
  margin-left: 16px;
  margin-right: 8px;
}

.input {
  @include font;
  appearance: textfield;
  text-align: center;
  background: rgba($color-neutral-500, 0.1);
  border-radius: 4px;
  height: 2rem;
  width: 2rem;
}
