@import 'styles/app';

.progress {
  @include elevation-shallow($color-neutral-900);
  margin-left: 20px;
  padding-right: 8px;
  border-radius: 4px;
  font-family: $font-default;
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $color-accent-500;
  padding-top: 8px;
  padding-bottom: 8px;
}
