@import 'styles/app';

.authenticating {
  @include elevation-shallow($color-neutral-900);

  padding: 8px;

  border-radius: 4px;

  font-family: $font-default;
  font-size: 12px;
  justify-content: center;
  color: $color-accent-500;

  display: inline-flex;
  align-items: center;
}
