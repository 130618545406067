@import 'styles/app';

.progressBar {
  height: 24px;
  color: $color-neutral-400;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.05em;
  position: relative;
  text-align: center;
}

.progressBarBackGround {
  background-color: rgba($color-accent-500, 0.1);
  height: 24px;
  left: 0;
  position: absolute;
}

.progressSuccess {
  height: 24px;
  background-color: rgba($color-accent-500, 0.1);
  color: $color-accent-600;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-align: center;
}

.progressError {
  height: 24px;
  background-color: rgba($color-negative-500, 0.1);
  color: $color-negative-700;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.05em;
  text-align: center;
}
