.accordionIcon {
  transition: 0.3s ease-in-out;
  margin-right: 8px;
}

.accordionIconOpen {
  transform: rotate(90deg);
}

.firstColumn {
  display: flex;
  align-items: center;
}

.row {
  cursor: pointer;
}
