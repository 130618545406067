@import 'styles/app';

.assetTitle {
  @include typography-card-sub-title;
  color: $color-neutral-700;
}

.buttonWrapper {
  display: flex;
  gap: 16px;
  justify-content: center;
}

.boldText {
  font-weight: 700;
}
