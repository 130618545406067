@import 'styles/app';

.container {
  margin-bottom: 32px;
}

.cloneContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  border: 1px solid $color-neutral-500;
  border-radius: 16px;
  padding: 24px 8px;
}

.cloneWarningMessage {
  display: flex;
  gap: 6px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
}

.messageEmptyPermissions {
  color: $color-rouge-600;
}

.messageChangePermissions {
  color: $color-amber-600;
}

.copyPermissionsModal {
  padding: 16px;
}

.copyPermissionsModalContent {
  margin-top: 8px;
}

.copyButton {
  margin-top: 16px;
}
