@import 'styles/app';

.textareaField {
  display: flex;
  flex-direction: column;
}

.textarea {
  border-radius: 4px;
  border: 1px solid transparent;
  background-color: rgba($color-neutral-500, 0.1);
  padding: 16px;
  resize: none;
  width: 100%;

  &::placeholder {
    color: rgba($color-neutral-600, 0.6);
  }

  &:hover {
    background-color: rgba($color-accent-500, 0.1);
  }

  &:focus {
    border-color: $color-accent-600;
    & ~ * {
      color: $color-accent-600;
    }
  }
  &:disabled {
    background-color: rgba($color-neutral-500, 0.3);
  }
}

.textareaError {
  border-color: $color-negative-600;
}

.textareaAutoResizable {
  overflow-y: hidden;
}

.label,
.message {
  @include typography-label;
  color: $color-neutral-600;
}

.label {
  margin-bottom: 8px;
}

.message {
  text-align: right;
  margin-top: 8px;
}

.labelError,
.messageError {
  color: $color-negative-600;
}

.value {
  border: 1px solid transparent;
}
