@import 'styles/app';

.auto-complete-default {
  .dropdown {
    @include elevation-deep($color-neutral-900);

    background-color: #fff;
    border-radius: 4px;
    padding: 8px 0px;

    .dropdown-item {
      cursor: pointer;
      font-size: 15px;
      line-height: 24px;
      padding: 8px 8px;

      &:hover {
        background-color: $color-neutral-200;
      }

      &.label {
        @include typography-label;

        color: $color-neutral-600;

        &:hover {
          background-color: transparent;
        }
      }

      &.-selected {
        font-weight: bold;
      }
    }
  }

  .input-field.-error + .dropdown {
    margin-top: -24px;
  }
}
