@import 'styles/app';

.infoArea {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 16px;
}

.label {
  @include typography-label;
  color: $color-neutral-600;
}

.value {
  color: $color-neutral-900;
  font-size: 15px;
  line-height: 24px;
  margin-top: 16px;
}

.loading {
  color: $color-neutral-300;
}
