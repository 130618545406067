@import 'styles/app';

.recurrentPaymentsContent {
  padding-top: 16px;
}

.remove {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 28px;
  margin-top: 24px;
}

.row {
  display: flex;
  margin-bottom: 16px;

  > * + * {
    margin-left: 16px;
  }

  > * {
    flex-basis: calc(50% - (16px / 2));
  }
}
