@import 'styles/app';

.select {
  background-image: url("data:image/svg+xml;utf8,<svg height='16' viewBox='0 0 24 24' width='16' xmlns='http://www.w3.org/2000/svg'><path fill='gray' d='M12 18C11.744 18 11.488 17.902 11.293 17.707L0.585999 7L2 5.586L12 15.586L22 5.586L23.414 7L12.707 17.707C12.512 17.902 12.256 18 12 18Z'  /></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  background-color: rgba($color-neutral-500, 0.1);
  padding: 0 16px;
  height: 2.75rem;
  width: 100%;
  font-family: $font-default;
  font-size: $font-size-default;

  &::placeholder {
    color: rgba($color-neutral-600, 0.6);
  }

  &:hover {
    background-color: rgba($color-accent-500, 0.1);
  }

  &:focus {
    border-color: $color-accent-600;
    & ~ * {
      color: $color-accent-600;
    }
  }
  &:disabled {
    background-color: rgba($color-neutral-500, 0.3);
  }

  &.-small {
    height: 2rem;
    font-size: 12px;
    padding: 0 8px;
  }

  option {
    color: $color-neutral-600;

    &:disabled {
      color: $color-neutral-400;
    }
  }
}
