@mixin field-styles {
  display: flex;
  flex-direction: column;
  > .container {
    box-sizing: border-box;

    position: relative;
    > .input {
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      border-color: transparent;
      background-color: rgba($color-neutral-500, 0.1);
      padding: 0 16px;
      font-size: $font-size-default;
      height: 2.75rem;
      width: 100%;
      &::placeholder {
        color: rgba($color-neutral-600, 0.6);
      }

      &:hover {
        background-color: rgba($color-accent-500, 0.1);
      }

      &:focus {
        border-color: $color-accent-600;
        & ~ * {
          color: $color-accent-600;
        }
      }
      &:disabled {
        background-color: rgba($color-neutral-500, 0.3);
      }
    }
    > .icon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      > path {
        fill: $color-neutral-600;
      }
    }
  }
  > .label,
  > .message {
    @include typography-label;
    color: $color-neutral-600;
  }

  > .label {
    margin-bottom: 8px;
  }

  > .message {
    text-align: right;
    margin-top: 8px;
  }

  > .value {
    border: 1px solid transparent;
  }

  &.-disabled {
    & > .container > select {
      background-color: rgba($color-neutral-500, 0.3);
      color: $color-neutral-400;
      pointer-events: none;
    }
    & > .label {
      color: $color-neutral-400;
    }
  }
  &.-empty {
    color: rgba($color-neutral-600, 0.6);
  }
  &.-error {
    & > .container {
      & > .icon > path {
        fill: $color-negative-600;
      }
      & > .select,
      & > .input {
        border-color: $color-negative-600;
      }
    }
    & > .label,
    & > .message {
      color: $color-negative-600;
    }
  }
  &.-icon {
    & .input {
      padding-right: 42px;
    }
  }
}
