@import 'styles/app';
.radio-switch {
  display: flex;
  gap: 8px;
  align-items: center;

  & > .inputLabel {
    color: $color-neutral-700;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  & > .checkbox {
    height: 0;
    width: 0;
    display: none;

    &:checked + .label {
      .button {
        left: calc(100% - 2px);
        transform: translateX(-100%);
        background: $color-neutral-50;
      }
      background: $color-accent-500;
      border-color: $color-accent-500;
    }
  }
  & > .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 40px;
    height: 24px;
    border: solid 1px $color-neutral-300;
    border-radius: 12px;
    position: relative;
    transition: background-color 0.2s;
    & > .button {
      content: '';
      position: absolute;
      top: calc(50% - 16px / 2);
      left: 2px;
      width: 16px;
      height: 16px;
      border-radius: 12px;
      transition: width 0.2s;
      background: $color-neutral-300;
    }
    &:active .button {
      width: 30px;
    }
  }

  & > .label.disabled {
    pointer-events: none;
  }

  &.loading,
  &.disabled {
    opacity: 0.4;
  }

  &.loading {
    cursor: wait;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
