// set styles for basic html tags, those who don't usually have a class.
@import 'styles/app';

@import url('https://fonts.googleapis.com/css?family=Inter:100,200,300,400,500,600,700,800,900&display=swap');

body {
  font-family: $font-default;
  font-size: 16px;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-os-font-smoothing: antialiased;
}

p {
  margin-top: 16px;
}

p > a {
  text-decoration: underline;
}

button {
  background-color: unset;
  cursor: pointer;
}

input[type='date']::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}
