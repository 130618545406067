@import 'styles/app';

.row {
  align-items: start;
  margin-top: 32px;
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
}

.isGroup {
  grid-template-columns: 1fr;
}

.title {
  @include typography-card-sub-title;
  color: $color-neutral-700;
}
