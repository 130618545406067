@import 'styles/app';

.labelContainer {
  @include typography-card-sub-title;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.labelContainerRigth {
  align-items: flex-start;
  margin-left: 8px;
}

.label {
  color: $color-neutral-900;
  font-size: 14px;
  font-weight: 500;
}

.labelRigth {
  font-size: 18px;
  font-weight: 400;
}
