@import 'styles/app';

.buttonWrap {
  min-width: 256px;
  min-height: 48px;
  padding: 16px;
}

.subtitle {
  @include typography-card-title;
  margin-left: auto;
  margin-right: auto;
  width: 256px;
}
