@import 'styles/app';

.timeline-content {
  > .item {
    font-family: $font-default;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $color-neutral-600;

    & + * {
      margin-top: 16px;
    }
  }

  > .actions {
    display: flex;
    flex-direction: row;
    margin-top: 16px;

    > .content-button {
      padding-left: 0px;
    }
  }
}

.attachment-list {
  > .item:not(:first-of-type) {
    margin-top: 16px;
  }
}
