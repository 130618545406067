@import 'styles/app';
.empty-state {
  display: flex;
  justify-content: center;
  padding: 20px 24px;
  align-items: center;
  background: transparent;
  border-radius: 8px;
  border: 1px solid $color-neutral-600;
  > .text {
    margin: 0;
    font-weight: 600;
    font-family: $font-default;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: $color-neutral-600;
  }
}
