@import 'styles/app';

.title {
  @include typography-card-sub-title;
  color: $color-neutral-700;
}

.formContainer {
  margin-top: 8px;
}
