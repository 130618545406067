@import 'styles/app';

.fullView {
  @include hide-on-print;
}

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}
