@import 'styles/app';

.dynamicTable {
  width: 100%;
  background-color: transparent;
  font-family: $font-default;
}

.body {
  font-size: 14px;
  color: $color-neutral-700;
}

.row {
  &:hover {
    background-color: $color-accent-50;
  }

  &.rowClickable {
    cursor: pointer;
  }
}

.emptyText {
  @include typography-normal;
  color: $color-neutral-600;
}
