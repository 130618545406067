.loaderWrapper {
  margin-top: 16px;

  * + * {
    margin-top: 16px;
  }
}

.loaderContent {
  display: flex;
  flex-flow: column;

  * + * {
    margin-top: 8px;
  }
}
