@import 'styles/app';
.files-list {
  border: 1px solid rgba($color-neutral-500, 0.3);
  border-radius: 8px;
  padding: 16px;

  & > .label {
    @include typography-card-sub-title;
    color: $color-neutral-700;

    & + .files {
      margin-top: 26px;
    }
  }

  & > .files > .empty-list {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: $color-neutral-600;
    text-align: center;
  }
}
