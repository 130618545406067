@import 'styles/app';

.executionTitleContainer {
  display: flex;
  justify-content: space-between;
}

.executionCard {
  margin-top: 16px;
}

.executionTitle {
  @include typography-card-sub-title;
  color: $color-neutral-900;
}

.executionCounter {
  @include typography-label;
  margin-left: 4px;
}

.bold {
  @include typography-label;
  margin-left: 8px;
  font-weight: bold;
}

.paragraph {
  @include typography-label;

  &:not(:first-child) {
    margin-top: 8px;
  }
}

.accordionTitle {
  display: flex;
  flex-direction: row;
}

.executionButtons {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.executionMetadata {
  padding-left: 28px;
}
