@import 'styles/app';

.actionsContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  background-color: white;
}

.actionsContent {
  display: flex;
  align-items: baseline;
  margin-top: 16px;
  justify-content: flex-end;
  position: relative;
  gap: 16px;
}

.searchField {
  width: 336px;
  align-self: flex-end;
}

.title {
  display: inline;
  margin-right: 8px;
}

.firstRowCell {
  border-top: 0 !important;
}

.headerContent {
  display: inline-block;
}

.counter {
  margin-right: 8px;
}

.stats {
  @include typography-label;
  color: $color-neutral-500;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 8px;
    margin-top: 0px;
  }
}
