@import 'styles/app';

.tab-container {
  @include container-width($fullWidth: true);

  & > .navigation {
    @include container-width;

    display: flex;
    box-shadow: 0 -1px 0 0 $color-neutral-200 inset;
    background-color: white;

    > .item {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-right: 24px;
      font-family: $font-default;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: $color-neutral-500;
      cursor: pointer;

      &:hover {
        background-color: rgba($color-accent-500, 0.1);
      }

      &.-active {
        display: block;
        color: $color-neutral-800;
        border-bottom: 2px solid $color-accent-600;
      }
    }
  }
}
