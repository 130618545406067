@import 'styles/app';

.modalBox {
  position: absolute;
  padding: 48px 64px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 16px;
  box-shadow: 0px 4px 16px rgba(40, 41, 47, 0.2);
  background: #fff;

  max-height: calc(100vh - 48px);
  overflow: visible;
  width: 560px;
}

.header {
  @include typography-card-title;
  color: $color-neutral-700;
}

.subtitle {
  color: $color-neutral-700;
  font-weight: normal;
  font-size: 15px;
}

.close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 48px;
  height: 16px;
  padding: 16px;
  display: inline-flex;
  align-items: center;
  color: #62667a;
  font-weight: 600;
}

.content {
  max-height: 75vh;
  overflow: auto;
  margin-top: 32px;
  font-size: 15px;
  line-height: 24px;
  color: $color-neutral-900;
  white-space: pre-line;
}

.small {
  width: 396px;
}

.medium {
  width: 752px;
}

.large {
  width: 80vw;
}

.modalOverlay {
  position: fixed;

  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: rgba(122, 126, 148, 0.3);

  @include z-index(modal);
}
