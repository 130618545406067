@import 'styles/app';

.item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  height: 32px;
}

.links {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  flex: 0 1 auto;
  line-height: normal;
}

.action {
  padding: 2px;
}
