@import 'styles/app';
.checkbox-default {
  > .label,
  > .label:before,
  > .message {
    @include typography-label;
    color: $color-neutral-600;
  }

  > .message {
    text-align: right;
    margin-top: 8px;
  }

  > .label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  > .input {
    display: none;

    & + .label:before {
      content: '';
      background-color: #fff;
      border-width: 1px;
      border-style: solid;
      border-color: $color-neutral-400;
      border-radius: 2px;
      box-sizing: border-box;
      line-height: 13px;
      display: inline-block;
      cursor: pointer;
      width: 16px;
      height: 16px;
      text-align: center;
      margin-right: 8px;
      color: #fff;
    }

    &:checked + .label:before {
      background-color: $color-accent-500;
      border-color: $color-accent-500;
      content: '✔';
    }

    &:disabled + .label:before {
      background-color: rgba($color-neutral-500, 0.3);
      border-color: rgba($color-neutral-500, 0.3);
      cursor: initial;
    }
  }

  &.-error {
    & > .input + .label:before {
      border-color: $color-negative-600;
    }
    & > .label,
    & > .message {
      color: $color-negative-600;
    }
  }

  &.-indeterminate {
    & > .input:not(:checked) + .label:before {
      background-color: $color-accent-500;
      border-color: $color-accent-500;
      content: '-';
    }
  }
}
