$z-index: (
  dropdown-tether: 40,
  modal: 30,
  sidebar: 20,
  sticky-header: 10
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key, $increment: 0) {
  z-index: z-index($key) + $increment;
}
