@import 'styles/app';

.titleDefault {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

.headerItem,
.headerInfo {
  @include typography-label;
  display: flex;
  align-items: center;
  color: $color-neutral-600;
}

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.actions {
  display: flex;
  gap: 8px;

  @media print {
    @include hide-on-print;
  }
}
