@import 'styles/app';

.groupField {
  display: flex;
  margin-top: 8px;

  > :first-child {
    flex-grow: 1;
  }

  > :first-child input,
  > :first-child select {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  > :last-child input,
  > :last-child select {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

.label {
  @include typography-label;
  color: $color-neutral-600;
}

.labelError {
  color: $color-negative-600;
}

.message {
  @include typography-label;
  color: $color-negative-600;
  text-align: right;
  margin-top: 8px;
}
