.search {
  display: flex;
  align-items: center;
}

.searchButton {
  margin-top: 24px;
  margin-left: 16px;
}

.searchInput {
  flex: auto;
}
