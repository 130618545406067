@import 'styles/app';
.inline-field {
  display: flex;
  flex-direction: column;

  > .input {
    background: none;
    border-bottom: 1px solid $color-neutral-700;
    color: $color-neutral-700;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    padding: 0px;
    height: 1.75rem;

    &::placeholder {
      color: rgba($color-neutral-600, 0.6);
    }

    &:focus {
      border-bottom: 1px solid $color-accent-600;
    }
  }

  > .value {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $color-neutral-700;
  }

  &.-error {
    & > .input {
      border-color: $color-negative-600;
    }
    & > .message {
      color: $color-negative-600;
      font-size: 12px;
      margin-top: 8px;
      display: inline-block;
    }
  }
}
