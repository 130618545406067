@mixin typography-navigation {
  font-family: $font-default;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

@mixin typography-small {
  font-family: $font-default;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
}

@mixin typography-normal {
  font-family: $font-default;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

@mixin typography-label {
  font-family: $font-default;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

@mixin typography-card-title {
  font-family: $font-default;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

@mixin typography-card-sub-title {
  font-family: $font-default;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.05em;
}
