@import 'styles/app';

.reportCompany {
  @include container-width;
}

.information {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 36px;
}

.col {
  flex-basis: calc(50% - (32px / 2));
}
