@import 'styles/app';

.infoTitle {
  @include typography-card-title;
  color: $color-neutral-700;
}

.infoValue {
  @include typography-card-title;
  font-size: 15px;
  font-weight: 400;
}
