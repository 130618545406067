@import 'styles/app';

.originModal {
  padding-top: 16px;
}

.title,
.subtitle {
  @include typography-card-title;
  color: $color-neutral-700;
  margin: 0;
  margin-bottom: 8px;
}

.subtitle {
  margin: 0;
  font-weight: 800;
}

.badge {
  border-radius: 8px;
}

.textRole {
  @include typography-label;
}

.textCentered {
  text-align: center;
}
