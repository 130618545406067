@import 'styles/app';

.datePicker {
  border-radius: 3px;
  border: 1px solid transparent;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  padding: 10px;

  :global(.react-datepicker__header) {
    background-color: white;
    border-bottom: none;
    padding-top: 0px;

    > :global(.react-datepicker__current-month) {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: $color-neutral-900;
      font-size: 16px;
      text-align: center;
      padding: 8px 0px;
      caption-side: initial;
    }

    > :global(.react-datepicker__header__dropdown) {
      margin: 8px 0px;
    }
  }

  :global(.react-datepicker__month-read-view--down-arrow),
  :global(.react-datepicker__year-read-view--down-arrow) {
    top: 4px;
    margin-left: 30px;
  }

  :global(.react-datepicker__navigation) {
    top: 20px;
  }

  :global(.react-datepicker__month) {
    border: 1px solid #e4e7e7;
    border-bottom-width: 0px;
    border-left-width: 0px;
  }

  :global(.react-datepicker__week) {
    border-bottom: 1px solid #e4e7e7;
  }

  :global(.react-datepicker__triangle) {
    border-bottom-color: #fff !important;
    left: 22px !important;
  }
}

.datePickerWeek {
  margin: 0;
  width: 39px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-600;
}

.datePickerDay {
  border: 0px solid #e4e7e7;
  border-left-width: 1px;
  color: $color-neutral-900;
  background: #fff;
  border-radius: 0px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 38px;
  margin: 0;
  width: 39px;
  height: 38px;

  &:hover {
    border-radius: 0px;
    background-color: rgba($color-neutral-500, 0.3);
  }

  &:global(.react-datepicker__day--selected) {
    background-color: $color-accent-500;
    border: 0px double $color-accent-500;
    color: white;
  }

  &:global(.react-datepicker__day--disabled) {
    color: $color-neutral-400;
    background-color: #fff;
  }
}

input.datePickerInput {
  background-image: url("data:image/svg+xml;utf8,<svg height='16' viewBox='0 0 24 24' width='16' xmlns='http://www.w3.org/2000/svg'><path d='M7 12H5V13H7V12Z' fill='gray' /><path fillRule='evenodd' d='M4 11H8V14H4V11Z' fill='gray' /><path d='M13 12H11V13H13V12Z' fill='gray' /><path fillRule='evenodd' d='M10 11H14V14H10V11Z' fill='gray' /><path d='M7 17H5V18H7V17Z' fill='gray' /><path fillRule='evenodd' d='M4 16H8V19H4V16Z' fill='gray' /><path d='M13 17H11V18H13V17Z' fill='gray' /><path fillRule='evenodd' d='M10 16H14V19H10V16Z' fill='gray' /><path d='M19 12H17V13H19V12Z' fill='gray'/><path fillRule='evenodd' d='M16 11H20V14H16V11Z' fill='gray' /><path d='M7 12H5V13H7V12Z' fill='gray' /><path fillRule='evenodd' d='M7 12V13H5V12H7Z' fill='gray' /><path d='M13 12H11V13H13V12Z' fill='gray' /><path fillRule='evenodd' d='M13 12V13H11V12H13Z' fill='gray' /><path d='M7 17H5V18H7V17Z' fill='gray' /><path fillRule='evenodd' d='M7 17V18H5V17H7Z' fill='gray' /><path d='M13 17H11V18H13V17Z' fill='gray' /><path fillRule='evenodd' d='M13 17V18H11V17H13Z' fill='gray' /><path d='M19 12H17V13H19V12Z' fill='gray' /><path fillRule='evenodd' d='M19 12V13H17V12H19Z' fill='gray' /><path fillRule='evenodd' d='M3 4C2.44772 4 2 4.44772 2 5V20C2 20.5523 2.44772 21 3 21H21C21.5523 21 22 20.5523 22 20V5C22 4.44772 21.5523 4 21 4H3ZM0 5C0 3.34315 1.34315 2 3 2H21C22.6569 2 24 3.34315 24 5V20C24 21.6569 22.6569 23 21 23H3C1.34315 23 0 21.6569 0 20V5Z' fill='gray' /><path fillRule='evenodd' d='M7 0V5H5V0H7Z' fill='gray' /><path fillRule='evenodd' d='M19 0V5H17V0H19Z' fill='gray' /><path fillRule='evenodd' d='M0 7H24V9H0V7Z' fill='gray' /></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
}

.datePickerContainerError {
  .datePickerLabel {
    color: $color-negative-600;
  }
}

.datePickerContainerDisabled {
  .datePickerLabel {
    color: $color-neutral-400;
  }
}

.datePickerLabel {
  @include typography-label;
  color: $color-neutral-600;
  margin-bottom: 8px;
}
