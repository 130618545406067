@import 'styles/app';

.debtorWrapper {
  display: flex;
  font-weight: 500;
  flex-flow: column;
}

.debtorName {
  display: block;
  font-weight: 500;
  color: $color-neutral-700;
}

.debtorDoc {
  font-size: 10px;
}

.judicialSituation {
  font-weight: 500;
  color: $color-neutral-700;
}

.debtorSumValue {
  font-weight: 900;
  color: $color-neutral-900;
}

.contractSumValue {
  font-weight: 700;
  color: $color-neutral-700;
}

.contractValue {
  font-weight: 500;
  color: $color-neutral-500;
}

.halfOpacity {
  opacity: 0.5;
}
