@import 'styles/app';

.timelineContent {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-600;
}

.content {
  margin-top: 0px;
}

.badge {
  color: $color-neutral-700;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  width: max-content;
}
