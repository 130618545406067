@import 'styles/app';
@import 'styles/mixins/input';

.select-field {
  @include field-styles;

  & > .container {
    > .input-field {
      margin: 0;
    }
  }
}
