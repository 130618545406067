@import 'styles/app';

.systemSidebar {
  @include z-index(sidebar);

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  width: $sidebar-width;
  min-height: 100vh;
  overflow: hidden;
  background-color: $color-neutral-100;
  transition: 0.2s;
}

.open {
  width: $sidebar-width-open;
  box-shadow: 0px 4px 16px rgba(40, 41, 47, 0.2);
}

.systemSidebar + .overlayer {
  @include z-index(sidebar, -1);

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color-neutral-900, 0.3);
}
