@import 'styles/app';
.file-icon {
  position: relative;
  margin-right: 7px;
  & > .extension {
    position: absolute;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 7px;
    line-height: 13px;
    color: #ffffff;
    text-align: center;
    letter-spacing: 0.1em;
    left: 5px;
    top: 8px;
    width: 26px;
    background: $color-accent-500;
  }
}
