@import 'styles/app';

.debtor-form {
  .input[name='name'] {
    width: 350px;
  }

  .content > * {
    margin-bottom: 0;
  }

  > .card-default {
    > .title {
      @include typography-card-sub-title;
    }

    > .form-container {
      margin-top: 0;
    }
  }
}
