@import 'styles/app';

.accordionTable {
  width: 100%;
  border-spacing: 0 1px;
  border-collapse: collapse;
}

.tableBody:before {
  content: '-';
  display: block;
  line-height: 8px;
  color: transparent;
}

.tableHeader {
  color: $color-neutral-400;
  font-size: 10px;
  line-height: 16px;
  font-weight: 800;
  letter-spacing: 0.05em;
  border-bottom: 1px solid $color-neutral-200;
  padding-bottom: 8px;
  width: auto;
  padding-left: 16px;
}

.tableHeader:last-of-type {
  padding-right: 12px;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

.right {
  text-align: right;
}

.row {
  color: $color-neutral-600;
  cursor: pointer;
  height: 32px;
}

.row:hover * {
  background-color: $color-accent-50;
  color: $color-accent-600;
  fill: $color-accent-600;
}

.row > :last-child {
  padding-right: 12px;
}

.row > :is(.cell, :first-child) {
  padding-left: 16px;
}

.cell {
  font-size: 12px;
  color: $color-neutral-700;
  font-weight: 500;
  border-bottom: 0px transparent;
  background-color: transparent;
  background-clip: padding-box;
  cursor: initial;
}

.clickableCell {
  background-color: #fff;
  cursor: pointer;
}

.visibleCell {
  border-bottom: 1px solid $color-neutral-100;
}

.indentCol {
  width: 37px;
  box-shadow: none;
  visibility: hidden;
}

.iconWrapper {
  display: flex;
  align-items: center;
  margin-left: -16px;
}

.expandTrigger {
  cursor: pointer;
  padding: 10px 16px;
  cursor: zoom-in;
}

.icon {
  transition: rotate 0.1s cubic-bezier(0.04, 0.62, 0.23, 0.98);
}

.iconExpanded {
  rotate: 90deg;
  cursor: zoom-out;
}
