// stylesheet used to reset native browser properties.

* {
  font-family: inherit;
  font-size: inherit;
  font-weight: 400;

  margin: 0;
  padding: 0;

  list-style: none;

  text-decoration: none;

  color: inherit;
  border: none;

  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  outline: none;
}

html,
body {
  min-height: 100%;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}
