@import 'styles/app';

.headerStatus {
  margin-top: 16px;
  display: flex;
  align-items: baseline;
  gap: 4px;
  color: $color-neutral-700;
  font-size: 12px;
  white-space: nowrap;
}

.bold {
  font-weight: 700;
}

.linksList {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
