@import 'styles/app';

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.accordionTable {
  width: 100%;
  border-spacing: 0 1px;
}

.tableBody:before {
  content: '-';
  display: block;
  line-height: 24px;
  color: transparent;
}

.tableHeader {
  color: $color-neutral-400;
  font-size: 10px;
  line-height: 16px;
  font-weight: 800;
  letter-spacing: 0.05em;
  border-bottom: 1px solid $color-neutral-200;
  padding-bottom: 8px;
  text-align: right;
  width: 10%;
}

.tableHeaderFirst {
  text-align: left;
  padding-left: 8px;
  width: 350px;
}

.tableHeaderSecond {
  text-align: left;
  width: 200px;
}

.tableHeaderLast {
  padding-right: 8px;
}

.debtorRow {
  color: $color-neutral-600;
  height: 56px;
}

.contractRow {
  color: $color-neutral-600;
  height: 40px;
}

.tableData {
  font-size: 12px;
  box-shadow: 0 1px 0px $color-neutral-100;
  background-color: #fff;
}

.tableDataLast {
  padding-right: 8px;
}

.indentCol {
  width: 37px;
  box-shadow: none;
}

.tdNotClickable {
  background-color: transparent;
}

.firstCol {
  font-size: 12px;
  font-weight: 500;
  color: $color-neutral-700;
  padding-left: 8px;
}

.debtorName {
  display: block;
  font-weight: 500;
  color: $color-neutral-700;
}

.debtorDoc {
  font-size: 10px;
}

.judicialSituation {
  font-weight: 500;
  color: $color-neutral-700;
}

.debtorSumValue {
  font-weight: 900;
  color: $color-neutral-900;
  text-align: right;
}

.contractSumValue {
  font-weight: 700;
  color: $color-neutral-700;
  text-align: right;
}

.contractValue {
  font-weight: 500;
  color: $color-neutral-500;
  text-align: right;
}

.contractOverdueAmount {
  display: flex;
  flex-direction: column;
}

.contractOverdueAmountDate {
  font-size: 10px;
}

@media print {
  .tableHeader {
    width: 90px;
  }

  .tableHeaderSecond {
    width: 100px;
    text-align: center;
  }

  .tableHeaderLast {
    width: 80px;
  }

  .tableHeaderOverdue {
    width: 80px;
  }

  .tableBody {
    page-break-inside: avoid;
  }

  .tdNotClickable {
    background-color: #fff;
  }

  .judicialSituation {
    text-align: center;
    word-break: break-word;
    word-wrap: break-word;
  }

  .tableData {
    border-bottom: 1px solid $color-neutral-200;
    box-shadow: none;
  }
}
