@import 'styles/app';

.table {
  margin-top: 8px;
}

.label {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $color-neutral-600;
}

.checkboxCell {
  width: 48px;
}

.actionCell {
  width: 90px;
}

.actionCell .icon {
  padding: 0;
}

.actions {
  display: flex;
  justify-content: center;

  > * + * {
    margin-left: 16px;
  }
}

.emptyState {
  color: $color-neutral-400;
  font-size: 12px;
  font-weight: 500;
}

.errorMessage {
  @include typography-label;
  text-align: center;
  color: $color-negative-600;
}

.hasError {
  color: $color-negative-600;
}
