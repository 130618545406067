@import 'styles/app';

.publicationLinkContainer {
  display: inline-flex;
  align-items: center;
}

.summaryPost {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.1em;
}

.summaryPostUser {
  font-size: 12px;
}

.summaryTitleIcon {
  margin-right: 8px;
}

.summaryTableRow {
  background: white;
}
