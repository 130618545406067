@import 'styles/app';

.linkedContractsLabel {
  @include typography-label;
  color: $color-neutral-600;
}

.linkedContracts {
  background-color: $color-neutral-50;
  padding: 8px 16px;
  margin-top: 8px;
  border-radius: 8px;
}

.linkedContractLink:not(:first-of-type) {
  margin-top: 8px;
}
