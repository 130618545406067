@import 'styles/app';

.report-show {
  @include container-width($fullWidth: true);

  .bottomheader {
    margin-top: 24px;
    display: flex;
    align-items: center;

    > span {
      font-family: $font-default;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    > span > .button-default {
      margin-left: 8px;
    }
  }

  .origintoggle {
    margin-top: 4px;
  }
}
