@import 'styles/app';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: white;
  padding: 20px 24px;
  border-radius: 8px;
  height: 50px;
}

.row + .row {
  margin-top: 16px;
}

.listWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.title {
  @include typography-card-sub-title;

  color: $color-neutral-700;
}

.text {
  @include typography-small;

  color: $color-neutral-500;
  line-height: 16px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}
