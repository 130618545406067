@import 'styles/app';

.form {
  position: relative;
}

.button {
  position: absolute;
  right: 0;
  top: -8px;
}

.filesLabel {
  @include typography-card-sub-title;
  margin-bottom: 8px;
}
