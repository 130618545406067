@import 'styles/app';

.container {
  height: 448px;
  width: 100%;
}

.cluster {
  display: flex;
  min-height: 24px;
  min-width: 24px;
  padding: 4px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background: $color-punch-600;
}

.cityCluster {
  display: flex;
  min-height: 24px;
  min-width: 24px;
  padding: 4px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background: $color-accent-500;
}

.pointCount {
  font-family: $font-default;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: #fff;
}
