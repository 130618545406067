@import 'styles/app';

.empty {
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  margin-top: 0px;
  color: $color-neutral-600;
}

.eventTitle {
  @include typography-card-sub-title;
  gap: 4px;
}
