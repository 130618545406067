@import 'styles/app';

.title {
  @include typography-card-sub-title;
}

.card {
  position: relative;
}

.remove {
  position: absolute;
  right: 17px;
  display: inline-flex;
  text-align: right;
}
