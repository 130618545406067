@import 'styles/app';

.accordionHeader {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: $color-neutral-900;
}

.caseFilesList {
  display: flex;
}

.filesList {
  flex: 1 1 0;
}

.cardTitle {
  @include typography-card-title;
}

.emptyList {
  color: $color-neutral-600;
  margin-left: 26px;
  margin-bottom: 16px;
  margin-top: 0px;
}
