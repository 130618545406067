.buttonWrapper {
  width: 100%;
  padding: 16px;
}

.modal {
  padding: 48px 64px;
}

.listWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}
