@import 'styles/app';

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.fullView {
  @include hide-on-print;
}

.number {
  @include typography-card-sub-title;
  margin-right: 4px;
}

.header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  grid-template-areas:
    'instrumentNumber amountContent'
    'percent .';
}

.instrumentNumberContent {
  grid-area: instrumentNumber;
}

.amountContent {
  @include typography-card-sub-title;

  grid-area: amountContent;
  align-items: center;
  text-align: right;
}

.percentContent {
  @include typography-small;

  grid-area: percent;
  line-height: 16px;
  color: $color-neutral-600;
}

.sharedGuarantee {
  margin-left: 4px;
}

.description {
  @include typography-label;
  color: $color-neutral-700;
}

@media print {
  .header {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'instrumentNumber'
      'amountContent'
      'percent';
    gap: 8px;
  }

  .amountContent {
    text-align: left;
  }

  .guaranteeContainer {
    break-inside: avoid;
  }

  .card {
    box-shadow: none;
    border: 1px solid rgba($color-neutral-600, 0.1);
  }
}
