@import 'styles/app';

.originRow {
  background: $color-neutral-50;
  color: $color-neutral-700;
}

.originRow:hover {
  background-color: $color-accent-50;
}

.identificationCell {
  margin-left: 48px;
}

@media print {
  .originRow {
    background: #fff;
  }
}
