@import 'styles/app';

.cell {
  line-height: 16px;
  border-top: 1px solid $color-neutral-200;
  padding: 16px 8px;
  text-align: left;
  width: 0;

  &.cellHeading {
    color: $color-neutral-400;
    font-size: 12px;
    font-weight: bold;
    border-top: none;
  }

  &.cellRight {
    text-align: right;
  }

  &.cellCenter {
    text-align: center;
  }

  &.cellCollapsible {
    width: 0;
    padding: 0;
    cursor: pointer;
    line-break: anywhere;
  }

  &:not(.cellExpanded).cellCollapsible {
    max-width: 5rem;

    .clipContent {
      &,
      & > span {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  &.cellExpanded {
    line-break: anywhere;
    width: 9rem;
    padding: 0;
  }
}

.expandButton {
  padding: 16px 8px;
}

.cellSmall {
  padding: 8px;
}
