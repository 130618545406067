@import 'styles/app';

.form-reclaiming {
  .form-container:first-of-type,
  .form-container:last-of-type {
    > .content > * {
      margin-top: 0px;
    }
  }

  .-well {
    margin-bottom: 16px;
  }
}
