@import 'styles/app';

.tooltipText {
  text-align: left;
  line-height: 22px;
}

.toggle-field {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > .label,
  > .message {
    @include typography-label;
    color: $color-neutral-600;
  }

  > .label {
    display: flex;
    margin-bottom: 8px;

    > .tooltipIcon {
      display: flex;
      margin-left: 8px;
    }
  }

  > .message {
    text-align: right;
    margin-top: 8px;
  }

  > .container {
    padding: 6px;
    height: 2.75rem;
    background-color: rgba($color-neutral-500, 0.1);
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;

    > .item {
      gap: 3px;
      flex: 1 1 0;
      display: flex;

      > label {
        @include typography-label;
        background-color: rgba(#fff, 0.6);
        width: 100%;
        border-radius: 4px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-neutral-400;
        cursor: pointer;
      }

      > input:checked:disabled + label {
        @include elevation-shallow($color-neutral-900);
        color: $color-accent-600;
      }

      > input:disabled + label {
        cursor: default;
      }

      > input:not(:disabled) + label:hover {
        color: $color-neutral-600;
        background-color: rgba(#fff, 0.8);
      }

      > input:checked:not(:disabled) + label {
        @include elevation-shallow($color-neutral-900);
        color: $color-accent-600;
        background-color: #fff;
      }
    }
  }

  &.-error {
    & > .container {
      border: 1px solid $color-negative-600;
    }
    & > .label,
    & > .message {
      color: $color-negative-600;
    }
  }
}
