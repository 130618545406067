@import 'styles/app';

.involvedParties {
  margin-top: 32px;
}

.cardLabel {
  @include typography-label;
  display: inline-block;
  color: $color-neutral-600;
  margin-bottom: 8px;
}

.tags {
  & > * {
    margin-right: 8px;
    margin-top: 8px;
    text-align: left;
  }

  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
}
