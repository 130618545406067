@import 'styles/app';

.adverse-parties-container {
  > .label {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $color-neutral-600;
  }

  > .card-default > .form-container > .content > .row {
    margin-top: 0;

    > .remove {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      max-width: 28px;
      margin-top: 24px;
    }
  }
}
