@import 'styles/app';

.header {
  border-top: 1px solid $color-neutral-200;
}

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.content {
  margin-top: 32px;
}
