@import 'styles/app';

.movements-container {
  & > .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .auto-update {
      display: inline-flex;
      align-items: center;
      height: 34px;

      & > .toggle {
        margin-left: 8px;
        color: $color-neutral-700;
        letter-spacing: 0.05em;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        &.-active {
          color: $color-accent-700;
        }
      }
    }

    & > .last-update {
      display: inline-flex;
      align-items: center;

      & > .label {
        margin: 0;
        font-family: $font-default;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.05em;
        color: $color-neutral-700;
      }
    }
  }

  & > .card-default {
    margin-top: 24px;

    > * + * {
      margin-top: 32px;
    }

    & > .empty {
      font-family: $font-default;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      color: $color-neutral-600;
    }
  }
}
