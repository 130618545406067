@import 'styles/app';

.header {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.title {
  display: inline-block;
}

.iconWrapper {
  margin-left: 8px;
}

.titleLink {
  @include typography-card-title;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
}

.info {
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-700;
}

.text:not(:first-child) {
  margin-top: 16px;
}

.tags {
  * {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.bold {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-left: 0.2em;
}

.distributionDateWrapper {
  display: flex;
  justify-content: space-between;
}

.distributionDate,
.value {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.indication {
  color: $color-neutral-500;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

.involvedParties {
  @include typography-label;
  color: $color-neutral-700;
}

.party {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: $color-neutral-600;
  margin-top: 8px;
}

@media print {
  .distributionDateWrapper {
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
  }
}
