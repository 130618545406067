@import 'styles/app';

.empty {
  color: $color-neutral-500;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.05em;
}

.images {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.image {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color-accent-500, 0.1);
  background-size: cover;
  border: 0;
  display: block;
  z-index: 1;
}

.imageLoading {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='margin: auto; background: rgba(0, 0, 0, 0) none repeat scroll 0%25 0%25; display: block; shape-rendering: auto;' width='147px' height='147px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' fill='none' stroke='%23ffffff' stroke-width='10' r='24' stroke-dasharray='113.09733552923255 39.69911184307752'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1.408450704225352s' values='0 50 50;360 50 50' keyTimes='0;1'%3E%3C/animateTransform%3E%3C/circle%3E%3C/svg%3E");
}

.thumbnail {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.thumbnail:hover > .overlay {
  visibility: visible;
}

.overlay {
  z-index: 2;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  gap: 16px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  visibility: hidden;
}

.pagination {
  margin-top: 16px;
}
