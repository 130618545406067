@import 'styles/app';

.pageLayout {
  display: flex;
  min-height: 100vh;
  background-color: $color-neutral-50;
}

.sidebar {
  @include hide-on-print;
  width: $sidebar-width;
}

.main {
  max-width: calc(100vw - #{$sidebar-width});
  flex-grow: 1;
  padding-bottom: 96px;
}

.main > * {
  @include container-width;
}
