@import 'styles/app';

.actions {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.content {
  margin-top: 32px;
  color: $color-accent-600;
}

.text {
  margin-top: 0;
  font: normal 500 15px/24px $font-default;
  color: $color-neutral-600;
}

.modal {
  padding: 32px;
}
