@import 'styles/app';

.modalBox {
  background-color: $color-neutral-900;
}

.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: $color-neutral-900;

  @include z-index(modal);
}

.modalClose {
  position: absolute;
  top: 0;
  right: 40px;
  cursor: pointer;
}

.swiperContainer {
  margin-top: 64px;
  position: relative;
}

.galleryWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gallery {
  max-width: 50vw;
  margin: 0 45px;
  width: 100%;
  height: calc(100vh - 298px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.imageSwiper {
  display: flex;
  justify-content: center;
}

.galleryImage {
  max-height: 100%;
  max-width: 100%;
}

.galleryNavigation {
  cursor: pointer;
  z-index: 10;
}

.carouselThumbsContainer {
  margin: auto;
  margin-top: 32px;
}

.carouselThumbnailWrapper {
  width: 96px !important;
  height: 96px;
  overflow: hidden;
  display: flex;
}

.carouselThumbnail {
  object-fit: cover;
  min-height: 96px;
  min-width: 96px;
  cursor: pointer;
  opacity: 0.4;
}

.carouselThumbnailActive > .carouselThumbnail {
  opacity: 1;
}

.actions {
  margin: auto;
  margin-top: 16px;
  max-width: 50vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalSlides {
  color: $color-neutral-300;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.05em;
}
