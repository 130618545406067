@import 'styles/app';

.pending,
.succeeded,
.nostatus,
.partial,
.error {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.pending {
  color: $color-accent-600;
}

.succeeded {
  color: $color-positive-600;
}

.partial {
  color: $color-dijon-600;
}

.nostatus {
  color: $color-neutral-400;
}

.error {
  color: $color-negative-600;
}

.icon {
  width: 16px;
  height: 16px;
  padding-right: 4px;
  flex-shrink: 0;
}

@media print {
  .pending,
  .succeeded,
  .nostatus,
  .partial,
  .error {
    color: $color-neutral-400;
  }

  .icon > * {
    fill: $color-neutral-400;
  }
}
