@import 'styles/app';

.statusBadgeWrapper {
  max-width: 190px;
}

.badge {
  width: fit-content;
  text-align: left;
}

.badgeTitle {
  letter-spacing: 0.1em;
  line-height: 12px;
  font-size: 10px;
  font-weight: 600;
  white-space: nowrap;
}

.badgeSubTitle {
  font-size: 9px;
  letter-spacing: 0.02em;
}

.grey {
  background-color: rgba($color-neutral-600, 0.1);
  color: $color-neutral-600;
}

.yellow {
  background-color: rgba($color-dijon-600, 0.1);
  color: $color-dijon-600;
}

.red {
  background-color: rgba($color-negative-600, 0.1);
  color: $color-negative-600;
}

.purple {
  color: $color-grape-600;
  background-color: rgba($color-grape-600, 0.1);
}

.orange {
  color: $color-tiger-600;
  background-color: rgba($color-tiger-600, 0.1);
}

.pink {
  color: $color-punch-600;
  background-color: rgba($color-punch-600, 0.1);
}

.aquamarine {
  color: $color-ocean-600;
  background-color: rgba($color-ocean-600, 0.1);
}

.green {
  color: $color-plant-600;
  background-color: rgba($color-plant-600, 0.1);
}

.blue {
  color: $color-blue-600;
  background-color: rgba($color-blue-600, 0.1);
}

.rouge {
  color: $color-rouge-600;
  background-color: rgba($color-rouge-600, 0.1);
}

@media print {
  .badgeTitle {
    color: $color-neutral-700;
  }

  .badgeSubTitle {
    color: $color-neutral-700;
  }
}
