@import 'styles/app';

.container {
  border: 1px solid rgba($color-neutral-500, 0.3);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
}

.title {
  @include typography-card-sub-title;

  color: $color-neutral-700;
}

.fileList {
  margin-top: 26px;
  margin-bottom: 26px;
}

.emptyList {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: $color-neutral-600;
  text-align: center;
}
