@import 'styles/app';

.subtitle-default {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;

  > .header-info,
  > .header-item {
    @include typography-label;
    display: flex;
    align-items: center;
    gap: 8px;
    color: $color-neutral-600;

    > .title {
      @include typography-card-sub-title;
      color: $color-neutral-700;
    }

    > svg {
      cursor: pointer;
    }

    > .status > span {
      display: flex;
    }

    > .value {
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  > .header-info {
    display: flex;

    > * + * {
      margin-left: 16px;
    }

    > .actions {
      display: flex;
      > * + * {
        margin-left: 8px;
      }
    }
  }
}
