@import 'styles/app';

.assetContent {
  padding: 16px;
  padding-bottom: 0;
  margin-bottom: 16px;
  border: 1px solid $color-neutral-300;
  border-radius: 8px;
}

.assetTitle {
  @include typography-card-sub-title;
  color: $color-neutral-700;
}

.assetGroupNameWrapper {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-700;
  margin-top: 8px;
}

.assetGroupName {
  font-weight: 600;
}
