@import 'styles/app';

.debtors-container {
  > .header-default > .container {
    margin-top: -32px;
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    border-bottom: 1px solid $color-neutral-200;

    > .content > .title {
      @include typography-card-title;
    }
  }

  > .content {
    @include container-width;

    padding-top: 32px;
  }
}
