@import 'styles/app';

.timeline {
  position: relative;
  display: flex;
  align-items: baseline;
  margin-top: 0;

  & > .body {
    width: 100%;

    & > .loader {
      > svg {
        display: block;

        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
      }
    }
  }

  & > .date {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: $color-accent-600;
    width: 100px;

    &.-blue {
      color: $color-accent-600;
    }

    &.-gray {
      color: $color-neutral-600;
      text-align: right;
    }
  }

  & > .line {
    z-index: 1;

    & > .circle {
      width: 12px;
      height: 12px;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      border: 2px solid $color-accent-600;
      margin: 0 18px;
      background: white;
      z-index: 1;

      &.-blue {
        border: 2px solid $color-accent-600;
      }

      &.-gray {
        border: 2px solid $color-neutral-600;
      }

      &.-loading {
        &::before {
          top: 16px;
        }
      }

      &.-small {
        &::before {
          height: calc(100% + 8px);
        }
      }

      &.-medium {
        &::before {
          height: calc(100% + 16px);
        }
      }

      &.-large {
        &::before {
          height: calc(100% + 24px);
        }
      }

      &::before {
        content: '';
        margin-left: 3px;
        top: 14px;
        position: absolute;
        height: calc(100% + 24px);
        width: 2px;
        background: $color-neutral-100;
        z-index: 0;
      }
    }
  }

  &:last-child > .line > .circle:before {
    width: 0;
  }

  & > div {
    & > .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.05em;
      color: $color-neutral-800;
      display: flex;
      justify-content: space-between;
    }

    & > .title + * {
      margin-top: 16px;
    }
  }

  & + .timeline {
    margin-top: 36px;
  }

  & + .-medium {
    margin-top: 16px;
  }

  & + .-small {
    margin-top: 8px;
  }

  & + .-large {
    margin-top: 24px;
  }

  &:first-of-type {
    &::before {
      top: 12px;
    }
  }

  &:last-of-type {
    &::before {
      height: 49px;
    }
  }

  &:only-of-type {
    &::before {
      visibility: hidden;
    }
  }
}
