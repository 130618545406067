@import 'styles/app';
.navigationItem {
  @include typography-navigation;

  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  color: $color-neutral-500;
  border-left: 4px solid transparent;
  cursor: pointer;
}

.navigationItem:hover:not(.top) {
  background-color: rgba($color-accent-500, 0.1);
  color: $color-neutral-800;
}

.active {
  border-color: $color-accent-600;
  color: $color-neutral-800;
}

.bottom {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 17px;
}

.text {
  color: $color-neutral-500;
  white-space: nowrap;
}

.textActive {
  color: $color-accent-600;
  font-weight: 600;
}

.children {
  height: 0px;
}
