@import 'styles/app';

.container {
  max-width: 1024px;
}

.buttonsContainer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.emptyListMessage {
  color: $color-neutral-500;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.05em;
}
