@mixin button-styles {
  @include elevation-deep($color-neutral-900);
  @include hide-on-print;

  padding: 8px 20px;
  border-radius: 4px;
  border-width: 1px;
  border-color: $color-neutral-600;
  border-style: solid;
  font-family: $font-default;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.05em;
  cursor: pointer;
  background-color: $color-neutral-600;
  color: white;

  &.-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:not(.-social) {
      > .icon {
        width: 16px;
        height: 16px;

        > path {
          fill: white;
        }
      }

      & > .children {
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }

  &:hover {
    background-color: $color-neutral-700;
    border-color: $color-neutral-700;
  }

  &.-highlight {
    @include elevation-deep($color-accent-900);
    background-color: $color-accent-500;

    &:hover {
      background-color: $color-accent-700;
    }
  }

  &.-social {
    @include elevation-shallow($color-neutral-900);
    background-color: #fff;
    color: $color-accent-600;
    border: none;
    white-space: nowrap;

    &.-small {
      padding: 2px 8px;
    }

    &.-icon {
      & > .children {
        margin-left: 2px;
      }
    }

    &.-disabled {
      background-color: #fff;
      color: $color-neutral-500;
      pointer-events: none;
      opacity: 1;
    }
  }

  &.-small {
    padding: 8px 16px;
    font-size: $small-button-font;
    line-height: 16px;
    letter-spacing: 0.1em;
    font-weight: 600;

    & > .children {
      font-weight: 600;
    }
  }

  &.-disabled {
    background-color: $color-neutral-500;
    pointer-events: none;
    opacity: 0.3;
  }

  &.-loading {
    pointer-events: none;
  }

  &.-outline {
    color: $color-neutral-600;
    background-color: transparent;
    border-color: $color-neutral-600;

    box-shadow: none;

    &:hover {
      background-color: $color-neutral-100;
    }

    &.-icon > .icon > path {
      fill: $color-neutral-600;
    }

    &.-highlight {
      color: $color-accent-600;
      border-color: $color-accent-600;

      &:hover {
        background-color: $color-accent-50;
      }

      > .icon > path {
        fill: $color-accent-600;
      }
    }

    &.-disabled {
      border-color: $color-neutral-500;

      &:hover {
        background-color: #fff;
      }

      > .icon > path {
        fill: $color-neutral-500;
      }
    }

    &.-danger {
      color: $color-negative-600;
      border-color: $color-negative-600;

      &:hover {
        background-color: $color-negative-50;
      }

      > .icon > path {
        fill: $color-negative-600;
      }
    }
  }

  &.-borderless {
    color: $color-neutral-600;
    border: transparent;
    background-color: transparent;
    letter-spacing: 0.01em;

    box-shadow: none;

    &:hover {
      background-color: transparent;
      border-color: transparent;
    }

    & .children {
      letter-spacing: 0.01em;
    }

    &.-icon > .icon > path {
      fill: $color-neutral-600;
    }

    &.-highlight {
      color: $color-accent-600;

      > .icon > path {
        fill: $color-accent-600;
      }

      &:hover {
        color: $color-accent-800;
      }
    }

    &.-disabled {
      > .icon > path {
        fill: $color-neutral-500;
      }
    }

    &.-danger {
      color: $color-negative-600;

      &:hover {
        background-color: $color-negative-50;
      }

      > .icon > path {
        fill: $color-negative-600;
      }
    }
  }

  &.-centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &.-block {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &.-large {
    padding: 16px 24px;
    font-weight: 600;
    font-size: $small-button-font;
    line-height: 16px;
    letter-spacing: 0.1em;
  }
}
