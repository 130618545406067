@import 'styles/app';

.tab-content {
  & > * {
    @include container-width;
    padding-top: 32px;
  }

  & > .-full {
    @include container-width($fullWidth: true);
  }
}
