@import 'styles/app';

.error-page-default {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  > .title {
    width: 40vw;
    border-bottom: 8px solid $color-neutral-200;
    padding-bottom: 16px;

    > h1 {
      font-size: 96px;
      color: $color-neutral-200;
    }
  }

  > .description {
    color: $color-neutral-700;
    font-size: 16px;
    margin-top: 56px;
    font-weight: 400;
  }

  > .actions {
    margin-top: 56px;

    > * + * {
      margin-left: 16px;
    }
  }
}
