@import 'styles/app';

.dropdown {
  @include elevation-deep($color-neutral-900);

  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 24px;
  text-align: center;
  padding: 8px 0;
}

.dropdownTether {
  @include z-index(dropdown-tether);
}

.eventsNone {
  pointer-events: none;
}

.option {
  display: inline-block;
  width: 100%;
  padding: 8px 24px;
  color: $color-accent-600;

  &:hover {
    background-color: rgba($color-neutral-500, 0.1);
  }
}

.optionSmall {
  font-size: $small-button-font;
}

.optionDisabled {
  color: $color-accent-500;
  pointer-events: none;
  opacity: 0.2;
}

.optionNegative {
  color: $color-negative-600;

  &:hover {
    background-color: rgba($color-negative-500, 0.1);
  }

  &.optionDisabled {
    color: $color-negative-500;
  }
}
