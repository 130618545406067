@import 'styles/app';
.navigationItem {
  @include typography-navigation;

  display: flex;
  justify-content: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 56px;
  color: $color-neutral-500;
  cursor: pointer;
}

.navigationItem:hover {
  background-color: rgba($color-accent-500, 0.1);
  color: $color-neutral-800;
}

.content {
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  color: $color-neutral-500;
  white-space: nowrap;
}

.active {
  border-color: $color-accent-600;
  color: $color-accent-600;
  font-weight: 600;
}
