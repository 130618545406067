@import 'styles/app';

.enabledAction {
  background-color: $color-accent-50;
}

.row {
  background-color: rgba($color-neutral-500, 0.05);
}

.nameColumn {
  display: flex;
  align-items: center;
  padding-left: 40px;
}

.toggleColumn {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
  height: 16px;
}
