@import 'styles/app';

.cardHeader {
  justify-content: space-around;
  display: flex;
}

.subtitle {
  @include typography-card-title;
  margin: 0;
}

.textAreaWithBottom {
  padding: 16px 10px 5px 16px;
}
