@import 'styles/app';

.header {
  border-bottom: solid 1px $color-neutral-200;
  padding-bottom: 8px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.profiles {
  display: flex;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.profileLineContainer {
  border: 1px solid $color-neutral-200;
  border-radius: 4px;
  padding: 8px;
}

.profileLine {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.profiles + .buttonContainer {
  margin-top: 16px;
  border-top: solid 1px $color-neutral-200;
  padding-top: 16px;
}

.profilesLabel {
  flex-basis: 25%;
}

.profilesValue {
  flex: 1;
}

.profilesLabel,
.profilesValue {
  font: normal 600 12px/16px $font-default;
  letter-spacing: 0.05em;
  color: $color-neutral-700;
}

.refreshProfileButtonContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.refreshProfileButtonContainerDisabled {
  cursor: default;
}

.refreshProfileButtonContainer > .refreshProfileButton {
  padding: 0;
}

.taskCopyButton {
  padding: 0;
  display: flex;
  margin-left: 8px;
}

.faded {
  color: $color-accent-300;
}

.status {
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
  font: normal 500 12px/16px $font-default;
}

.info {
  color: $color-neutral-400;
}

.pending,
.profilesLink {
  color: $color-accent-600;
}

.succeeded {
  color: $color-positive-600;
}

.notFound {
  color: $color-tiger-600;
}

.failed {
  color: $color-negative-600;
}

.modalContent {
  margin-top: 16px;
}

.modalText {
  margin-top: 8px;
  font: normal 500 15px/24px $font-default;
  color: $color-neutral-600;
}

.modalButtonsContainer {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
}
