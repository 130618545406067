@import 'styles/app';

.actions {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  > * + * {
    margin-left: 16px;
  }
}

.text {
  margin-top: 0px;
  font-weight: 400;

  font-style: normal;
  font-size: 15px;
  line-height: 24px;
  color: $color-neutral-700;
}

.bold {
  font-weight: 700;
}

.modal {
  padding: 32px;
}
