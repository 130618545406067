@import 'styles/app';

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.wrapper {
  background-color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
}

.hideOnPrint {
  @include hide-on-print;
}
