@import 'styles/app';

.accordionHeader {
  display: flex;
  width: 100%;
  margin: 0;
  border: none;
}

.title {
  @include typography-card-title;
}
