@import 'styles/app';

.layout-authentication {
  background-color: $color-accent-500;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;

  align-items: center;
  justify-content: center;

  > .logo {
    margin-right: 128px;
    @media screen and (min-width: 960px) {
      margin-right: 192px;
    }
    @media screen and (min-width: 1280px) {
      margin-right: 256px;
    }
  }

  > .card {
    @include elevation-deep($color-accent-900);

    width: 430px;
    background-color: #fff;
    padding: 48px 64px;
    border-radius: 16px;
    > *:not(:first-child) {
      margin-top: 32px;
    }
    > p.paragraph {
      color: $color-neutral-900;
      font-family: $font-default;
      font-size: 19px;
      line-height: 24px;
    }
    > form > *:not(first-child) {
      margin-top: 32px;
    }
  }
}
