@import 'styles/app';

.contractsPage {
  @include container-width;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.actions {
  display: flex;
  gap: 8px;
}

.status {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-700;
}

.emptyDebtorsList {
  font-family: Inter;
  font-size: 15px;
  line-height: 24px;
  color: $color-neutral-600;
  margin-top: 24px;
}
