@import 'styles/app';

.title {
  @include typography-card-title;
  color: $color-neutral-700;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.name,
.document {
  @include typography-card-sub-title;
}

.suspect,
.suspectWrapper {
  display: flex;
}

.suspect {
  align-items: center;
  gap: 8px;
}

.suspectWrapper {
  justify-content: space-between;
}

.card {
  padding: 16px;
}

.fullView {
  @include hide-on-print;
}

@media print {
  .card {
    break-inside: avoid;
    box-shadow: none;
    border: 1px solid rgba($color-neutral-600, 0.1);
  }

  .suspectWrapper {
    justify-content: flex-start;
    flex-flow: column;
    gap: 8px;
  }
}
