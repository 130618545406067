@import 'styles/app';

.createdBy {
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-600;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 16px;
}
