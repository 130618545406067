@import 'styles/app';

.tableTitle {
  @include typography-navigation;
  margin-top: 10px;
  background: $color-accent-50;
  color: $color-neutral-600;
}

.tableLabel {
  font-weight: 500;
}

.accordionTitle {
  color: $color-neutral-600;
  background: $color-accent-50;
  margin-top: 10px;
  padding: 8px 5px;
}

.accordionTable {
  padding-left: 40px;
}

.lastTable {
  margin-bottom: 10px;
}

.contentCell {
  width: 40%;
}

.contentReducedCell {
  width: 41%;
}
