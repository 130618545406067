@import 'styles/app';

.relatedCard {
  background-color: $color-neutral-100;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: row;
  padding: 16px;
  margin-right: 10px;
  margin-left: 10px;
}

.relatedColumn {
  display: flex;
  flex-direction: column;
  flex: 1 100%;
}

.listHeader {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-700;
}

.listItem {
  margin-top: 8px;
}
