@import 'styles/app';

.title {
  color: $color-neutral-600;
  margin: 24px 0 16px;
}

.item {
  padding: 8px 24px;
  box-shadow: 0 4px 16px $color-neutral-100;
}

.item:hover {
  background: $color-neutral-50;
}

.feedback {
  color: $color-neutral-500;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  gap: 8px;
}
