@import 'styles/app';

.nirfCodes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  @include typography-label;
  font-weight: 500;
  padding-left: 0;
  color: $color-neutral-600;
}

.nirfValues {
  color: $color-neutral-900;
  font-weight: 500;
}

.deleteButton {
  padding: 0;
}
