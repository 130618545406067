@import 'styles/app';

@mixin form-columns($columns, $gap) {
  & > .content {
    grid-template-columns: repeat($columns, 1fr);
    grid-column-gap: $gap;
    > .input-field,
    > .select-field,
    > .toggle-field {
      flex-basis: calc((100 / $columns * 1%) - ($gap / 2));
    }
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  $gap: 16px;

  > * + * {
    margin-top: 8px;
  }

  > h4 {
    @include typography-label;
    letter-spacing: 0.05em;
    color: $color-neutral-600;
  }

  > .content {
    display: grid;
    border-radius: 8px;

    > * {
      flex-basis: calc(100% - (#{$gap} / 2));
      margin-bottom: 16px;
      margin-top: 16px;
    }

    > * .value {
      margin-top: 16px;
    }

    > .row {
      display: flex;
      margin-bottom: 16px;

      > * + * {
        margin-left: 16px;
      }

      > * {
        flex-basis: calc(50% - (#{$gap} / 2));
      }
    }
  }

  &.col-2 {
    @include form-columns(2, $gap);
  }

  &.col-3 {
    @include form-columns(3, $gap);
  }

  &.col-4 {
    @include form-columns(4, $gap);

    &.-last-double > .content {
      grid-template-columns: 1fr 1fr 1fr 2fr;
    }
  }

  &.col-5 {
    @include form-columns(5, $gap);

    &.-last-small > .content {
      grid-template-columns: 1fr 1fr 1fr 1fr 0.66fr;
    }
  }

  &.-well > .content {
    background-color: $color-neutral-50;
  }

  &.-white > .content {
    background-color: #fff;
    padding: 16px;
  }

  &.-no-padding > .content {
    padding: 0 16px;
  }
}
