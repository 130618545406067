@import 'styles/app';

.spinnerWrapper {
  width: 42px;
  height: 18px;
}

.spinner {
  display: block;
  $size: 16px;
  float: left;
  margin-right: -$size;
  position: absolute;
  top: calc(50% - #{$size}/ 2);
  left: calc(50% - #{$size}/ 2);
  text-indent: -9999em;
  width: $size;
  height: $size;
  border-radius: 50%;
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    border-radius: 100% 0 0 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &.spinnerWhite {
    &:before {
      background: white;
    }

    &:after {
      background: $color-accent-500;
    }

    background: white;
    background: linear-gradient(
      to right,
      white 10%,
      rgba(255, 255, 255, 0) 42%
    );
  }

  &.spinnerBlue {
    &:before {
      background: $color-accent-500;
    }

    &:after {
      background: white;
    }

    background: white;
    background: linear-gradient(
      to right,
      $color-accent-500 10%,
      rgba(255, 255, 255, 0) 42%
    );
  }
}

.big {
  $size: 128px;
  margin-right: -$size;
  top: calc(50% - #{$size}/ 2);
  left: calc(50% - #{$size}/ 2);
  width: $size;
  height: $size;
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
