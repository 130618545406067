@import 'styles/app';

.accordion {
  margin: 16px 0px;
}

.card {
  padding: 16px;
}

.deleteButton {
  padding: 0;
  margin-top: 0;
}

.timelineContainer {
  margin: 16px 16px 0px 16px;
}
