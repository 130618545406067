@import 'styles/app';

.duplicateLabel {
  @include typography-card-sub-title;
}

.actions {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  gap: 8px;
}

.card {
  padding: 24px 16px;
  position: relative;
  scroll-margin-top: 150px;
}
