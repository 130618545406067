@import 'styles/app';

.badgesWrapper {
  margin: 0;
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}

.badgeContainer {
  display: flex;
  min-width: 240px;
  min-height: 22px;

  flex-basis: calc(25% - 16px);
  flex-flow: column;
  gap: 8px;
}

.active {
  background-color: $color-neutral-50;
  border-radius: 8px;
  padding: 8px;
  flex-basis: 100%;
}

.badges {
  display: flex;
  min-height: 22px;
  gap: 16px;
}

.label {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: $color-neutral-900;
}

.advancedInfoWrapper {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.advancedInfo {
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-700;
}

.info {
  font-weight: 600;
  white-space: nowrap;
}

.subInfo {
  white-space: nowrap;
  margin-left: 4px;
}

@media print {
  .active {
    background-color: #fff;
    border: 1px solid rgba($color-neutral-600, 0.1);
  }
}
