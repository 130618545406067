@import 'styles/app';

.summaryTableRow {
  background: white;
}

.ownerNameColumn {
  white-space: nowrap;
}

.totalAreaSummary {
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: $color-neutral-700;
  margin-top: 4px;
}

.flex,
.areaTooltip svg {
  display: flex;
  align-items: center;
}

.areaTooltip {
  margin-right: 8px;
}
