.orderBy {
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  margin-left: 6px;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
}
