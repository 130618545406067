@import 'styles/app';

.header-default {
  @include container-width($fullWidth: true);

  background-color: white;

  & > .container {
    @include container-width;

    padding-top: 32px;
    padding-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  .content {
    > .link-default {
      margin-bottom: 32px;
    }
  }

  &.-sticky {
    position: sticky;
    top: 0;
    @include z-index(sticky-header);
  }

  .actions > * + * {
    margin-left: 10px;
  }

  .actions {
    display: flex;
    flex-shrink: 0;
  }
}
