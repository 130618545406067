$color-neutral-50: #f3f4f6;
$color-neutral-100: #e2e3e9;
$color-neutral-200: #cbcdd7;
$color-neutral-300: #b2b5c2;
$color-neutral-400: #969aab;
$color-neutral-500: #7a7e94;
$color-neutral-600: #62667a;
$color-neutral-700: #4b4e5d;
$color-neutral-800: #373943;
$color-neutral-900: #28292f;
$color-accent-50: #e8eaf6;
$color-accent-300: #7986cb;
$color-accent-500: #3f51b5;
$color-accent-600: #3949ab;
$color-accent-700: #303f9f;
$color-accent-800: #283593;
$color-accent-900: #1a237e;
$color-negative-50: #f3e2e3;
$color-negative-600: #e53935;
$color-negative-500: #f44336;
$color-negative-700: #d32f2f;
$color-positive-50: #e2ede5;
$color-positive-600: #43a047;
$color-positive-700: #388e3c;
$color-grape-600: #6633cc;
$color-dijon-600: #dd9900;
$color-punch-600: #cc3388;
$color-tiger-600: #ee6633;
$color-ocean-600: #339999;
$color-plant-600: #669933;
$color-blue-600: #0f56c1;
$color-rouge-600: #bb3355;
$color-amber-600: #ee9933;
