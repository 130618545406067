.container {
  cursor: default;
  text-align: center;
}

.content {
  margin: 3px -1px;
}

.instagram {
  margin-top: 8px;
}

.publicationLinkWrapper {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.bookmark {
  margin-left: 12px;
}
