@import 'styles/app';

.missingInfoContainer {
  display: flex;
  align-items: center;
}

.missingInfoMessage {
  color: $color-negative-600;
  font-size: 12px;
  font-weight: 500;
  margin-left: 4px;
}
