@import 'styles/app';

.header {
  @include container-width($fullWidth: true);
}

.body {
  @include container-width;
  margin-top: 32px;
}

.headerLink {
  color: $color-accent-600;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
}

.bottomheader {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.reportStatus {
  font-family: $font-default;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.headerButton {
  margin-left: 16px;
}

.mapWrapper {
  @include container-width($fullWidth: true);
}
