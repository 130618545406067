@import 'styles/app';

.defaultRow {
  height: 50px;
}

.isNew {
  background: $color-neutral-50;
}

.lastSeenAt {
  background: $color-neutral-100;
  color: $color-neutral-600;
}

.defaultChildrenRow {
  background-color: rgba($color-accent-500, 0.05);
  height: 40px;
}
