@import 'styles/app';

.content {
  padding: 4px 8px;
}

.pagination {
  margin-top: 32px;
}

.emptyText {
  @include typography-label;
  color: $color-neutral-600;
  margin: 0;
}
