@import 'styles/app';

.loadingWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 16px;
}

.emptyText {
  @include typography-normal;
  color: $color-neutral-600;
  margin-top: 16px;
}
