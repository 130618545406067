@import 'styles/app';

.lawsuitsList {
  margin-top: 32px;

  > .header {
    @include typography-card-title;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $color-neutral-600;

    & > svg {
      margin-right: 8px;
    }
  }

  & > .cardsWrapper {
    margin-top: 16px;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  & > .masonryListWrapper {
    margin-top: 16px;
  }

  & > .empty {
    color: $color-neutral-600;
    font-size: 15px;
    line-height: 24px;
    padding-left: 28px;
  }
}
