@import 'styles/app';

.summaryTableRow {
  background: white;
}

.businessNameSummary {
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: $color-neutral-700;
  margin-top: 4px;
}

.prlSummary {
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  color: $color-neutral-700;
  margin-top: 4px;
}
