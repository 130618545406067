@import 'styles/app';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 8px;
}

.wrapper:hover {
  background-color: $color-neutral-50;

  .button {
    opacity: 1;
  }
}

.link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $color-accent-600;
}

.linkRead {
  color: $color-neutral-900;
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.tag {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.timestamp {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $color-accent-300;
  margin-top: 4px;
}

.timestampRead {
  color: $color-neutral-500;
}

.button {
  display: flex;
  align-items: center;
  opacity: 0;
  flex-shrink: 0;
  height: 18px;
  width: 18px;
  margin-left: 8px;
  border-radius: 50%;
  border: 2px solid $color-accent-500;
  transition: opacity 0.1s ease;
}

.buttonRead {
  background-color: $color-accent-500;
}
