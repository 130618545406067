@import 'styles/app';

.header {
  border-top: 1px solid $color-neutral-200;

  > .container {
    padding-bottom: 24px;
  }
}

.title {
  @include typography-card-title;
  color: $color-neutral-900;
}

.headerContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
