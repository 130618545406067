@import 'styles/app';

.lawsuitCard {
  margin-bottom: 0px;
}

.title {
  margin-bottom: 8px;
}

.link {
  @include typography-card-title;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

.subtitle {
  font-size: 15px;
  line-height: 24px;
  color: $color-neutral-900;
  margin-top: 8px;
}

.description {
  color: $color-neutral-600;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
}
