@import 'styles/app';

.table {
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 8px;
}

.infoTexts {
  color: $color-accent-600;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
}

.assetSelectWrapper {
  cursor: pointer;
}

.assetSelected {
  color: $color-accent-700;
  background-color: $color-accent-50;
}

.assetDisabled {
  color: $color-neutral-400;
  cursor: not-allowed;
}

.checkbox {
  visibility: hidden;
  border-radius: 2px;
  background-color: $color-accent-500;
  border-color: $color-accent-500;
}

.roundedCheckbox {
  border-radius: 50%;
}

.showCheckbox {
  visibility: visible;
}

.activeFilters {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.filtersBadge {
  white-space: nowrap;
  max-width: max-content;
}
