@import 'styles/app';

.card-default {
  @include elevation-deep($color-neutral-900);
  > * + * {
    margin-top: 16px;
  }

  background-color: #fff;
  border-radius: 8px;
  padding: 28px 24px;

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;

    > .title-container {
      > h2 {
        @include typography-card-title;
        color: $color-neutral-700;
        margin-bottom: 8px;
        margin-top: 4px;
        display: inline-block;
      }

      > .title-component {
        display: inline-block;
        margin-left: 5px;
      }
    }

    > .actions {
      display: flex;
      align-items: center;

      > * + * {
        margin-left: 8px;
      }

      > .cancel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;

        > svg {
          margin-right: 0;
        }
      }
    }
  }

  &.-small {
    padding: 8px 16px 16px;
  }

  &.-medium {
    padding: 16px;
  }

  .card-default {
    background-color: $color-neutral-50;
    box-shadow: 0px 0px 0px;
  }
}
