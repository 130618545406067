@import 'styles/app';

.header {
  @include typography-label;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-neutral-600;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.info {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.value {
  font-size: 18px;
  margin-left: 8px;
  line-height: 24px;
  font-weight: 400;
}
