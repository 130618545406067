@import 'styles/app';

.error {
  padding: 8px 16px;
  font-weight: 500;
  font-size: 15px;
  color: $color-negative-600;
  background-color: $color-neutral-50;
  border-bottom: 1px solid $color-neutral-200;
}

.pagination {
  margin-top: 24px;
}
