@import 'styles/app';

.judicial-recovery-card {
  & > .title {
    @include typography-card-sub-title;
  }

  & > .form-container {
    margin-top: 0;
  }
}
