@import 'styles/app';

.trigger {
  border-radius: 16px;
  height: 32px;
  line-height: 14px;
  padding: 8px;
  min-width: 32px;

  &:hover {
    background: $color-neutral-100;
  }
}

.triggerActive {
  background: $color-accent-500;

  &:hover {
    background: $color-accent-500;
  }
}

.counter {
  color: #fff;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  margin-left: 5px;
}

.triggerWrapper {
  display: flex;
}

.dropdownTether {
  @include z-index(dropdown-tether);
}
