@import 'styles/app';

.container {
  display: flex;
  gap: 8px;
}

.badge {
  width: fit-content;
}

@media print {
  .badge {
    background-color: transparent;
    border: 1px solid $color-neutral-100;
  }
}
