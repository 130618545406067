@import 'styles/app';

.stateRow {
  background: white;
}

.stateCell {
  cursor: pointer;
}

.stateCellSummary {
  padding: 16px 12px !important;
}

.stateCellIcon {
  display: flex;
  align-items: center;
  color: $color-neutral-700;
}

.stateCellIconOpen {
  transform: rotate(90deg);
  transition: 0.3s ease-in-out;
  margin-right: 8px;
}

.stateCellIconClose {
  margin-right: 8px;
  transition: 0.3s ease-in-out;
}

.locationQuantityCell {
  font-weight: bold;
}
