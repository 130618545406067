@import 'styles/app';

.errorTitle {
  @include typography-card-title;
  color: $color-neutral-700;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 30px 0px 30px 0px;
  }

  .main {
    background-color: white;
    max-width: 100%;
    width: 100%;
    padding-bottom: 0px;
  }

  .main > * {
    max-width: 100%;
    width: 100%;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 8px;
    color: $color-neutral-700;
  }
}
