@import 'styles/app';

.cardWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.cardTitle {
  @include typography-card-sub-title;
  color: $color-neutral-700;
}

@media print {
  .card {
    box-shadow: none;
    border: 1px solid rgba($color-neutral-600, 0.1);
  }
}
