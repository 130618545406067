@import 'styles/app';

.page {
  font-family: Inter;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: $color-neutral-600;

  border: 1px solid $color-neutral-400;
  border-radius: 4px;
  width: 32px;
  height: 32px;

  position: relative;
  display: grid;
  place-items: center;

  &:not(.current):hover {
    background-color: rgba($color-accent-500, 0.1);
    color: $color-accent-600;

    & > .label > :global(.icon) > * {
      fill: $color-accent-600;
    }
  }

  &[disabled]:hover {
    background: unset;
    cursor: not-allowed;
  }

  & > .hoverLabel {
    opacity: 1;
  }

  & > .hoverChevron {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    & > .hoverLabel {
      opacity: 0;
    }
    & > .hoverChevron {
      opacity: 1;
    }
  }

  &:not(:first-child) {
    margin-left: 8px;
  }
}

.current {
  border: 1px solid $color-accent-600;
  color: $color-accent-600;
  transition: 0.2s;
}

.borderless {
  border: 1px none;
}

.label {
  height: 16px;

  & > :global(.icon) {
    height: 16px;
    width: 16px;
  }
}
