@mixin elevation-shallow($color) {
  box-shadow: 0 2px 8px rgba($color, 0.2);
}

@mixin elevation-deep($color) {
  box-shadow: 0 4px 16px rgba($color, 0.2);
}

@mixin container-width($fullWidth: false) {
  max-width: calc(100vw - #{$sidebar-width});
  padding-left: $container-padding-size;
  padding-right: $container-padding-size;

  @if $fullWidth {
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@mixin button-style($color, $hover-color) {
  color: $color;
  border-color: $color;

  &:hover {
    background-color: $hover-color;
  }
}

@mixin danger-button-preset() {
  @include button-style($color-negative-600, $color-negative-50);
}

@mixin highlight-button-preset() {
  @include button-style($color-accent-600, $color-accent-50);
}
