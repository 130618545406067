@import 'styles/app';

.trigger {
  margin: 0;
  margin-top: 3px;
}

.activitiesBadge {
  @include typography-card-sub-title;
  font-size: 13px;
  letter-spacing: 0.1em;
}

.badgeWrapper {
  max-width: initial;
}

.title {
  @include typography-card-title;
}

.titleStack {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}
