@import 'styles/app';

.content {
  @include container-width;

  padding-top: 32px;
}

.container {
  margin-top: -32px;
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid $color-neutral-200;
}

.title {
  @include typography-card-title;
  color: $color-neutral-900;
}

.changedAt {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: $color-neutral-700;
}
