@import 'styles/app';

.title {
  @include typography-label;
  letter-spacing: 0.05em;
  color: $color-neutral-600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.totalSum {
  color: $color-neutral-900;
  font-weight: 500;
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  gap: 8px;
}
