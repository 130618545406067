@import 'styles/app';

.header {
  margin-top: -32px;
}

.content {
  @include container-width;

  padding-top: 32px;
}

.title {
  font-size: 20px;
  line-height: 24px;
}

.changed {
  color: $color-neutral-700;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: block;
  margin-top: 8px;
}
