@import 'styles/app.scss';

$animation-easing: cubic-bezier(0.04, 0.62, 0.23, 0.98);

.label,
.message {
  @include typography-label;
}

.label {
  color: $color-neutral-600;
  margin-bottom: 8px;
  display: block;
}

.labelError,
.message {
  color: $color-negative-600;
}

.message {
  text-align: right;
  margin-top: 8px;
}

.selectValues {
  display: flex;
  gap: 8px;
  margin: 0;
  padding: 8px;
  padding-left: 16px;

  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  background-color: rgba($color-neutral-500, 0.1);
  font-size: 15px;
  width: 100%;
  min-height: 42px;
  flex-wrap: wrap;

  background-image: url("data:image/svg+xml;utf8,<svg height='16' viewBox='0 0 24 24' width='16' xmlns='http://www.w3.org/2000/svg'><path fill='gray' d='M12 18C11.744 18 11.488 17.902 11.293 17.707L0.585999 7L2 5.586L12 15.586L22 5.586L23.414 7L12.707 17.707C12.512 17.902 12.256 18 12 18Z'  /></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
}

.disabledSelectValues {
  background-color: rgba($color-neutral-500, 0.3);
}

.dropdownList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dropdownCard {
  @include z-index(dropdown-tether);
  @include elevation-deep($color-neutral-900);

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% + 1px));
  padding: 8px 16px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ffffff;

  transition: transform 0.15s $animation-easing;
}

.dropdownCardClosed {
  transform: translateY(50%) scaleY(0);
  transition-delay: 0.2s;
}

.container {
  position: relative;
}

.empty {
  color: rgba($color-neutral-600, 0.6);
}
