@import 'styles/app';

.text {
  @include typography-navigation;
  font-size: 12px;
  color: $color-neutral-600;
  font-weight: 400;
  letter-spacing: 0;
  padding-left: 8px;
}

.noActivities {
  padding: 0 8px 8px 8px;
}

.triggerContainer {
  margin: 0px 0px 8px 8px;
}

.centered {
  display: flex;
}

.textTrigger {
  padding-top: 2px;
}

.activitiesCell {
  padding: 8px !important;
  vertical-align: initial !important;
  border-top-color: $color-neutral-100 !important;
}

.emptyCell {
  pointer-events: none;
  border: none !important;
}

.description {
  white-space: pre-line;
}

.activityRow:hover {
  & .emptyCell {
    background-color: $color-neutral-50;
  }
}
