@import 'styles/app';

.contract-page {
  margin-top: -32px;

  & > .header-default {
    border-bottom: 1px solid $color-neutral-200;

    & > .container > .content {
      & > .title-default {
        font-size: 20px;
        line-height: 24px;
      }

      & > .changed,
      & > .info {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        display: block;
        margin-top: 8px;

        & > b {
          font-weight: bold;
        }
      }

      & > .changed {
        color: $color-neutral-700;
      }

      & > .info {
        color: $color-accent-700;
      }
    }
  }

  & > .content {
    @include container-width;

    padding-top: 32px;
  }
}
