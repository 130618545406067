@import 'styles/app';

.formContainer {
  display: flex;
  flex-direction: column;
}

.modal {
  padding: 16px;
}

.nameInput {
  flex-grow: 1;
}

.nameInputLabel {
  color: $color-neutral-600;
  font-weight: 500;
}

.nameInputContainer {
  display: flex;
  align-items: center;
}

.nameSpan {
  margin-right: 4px;
}
