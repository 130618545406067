@import 'styles/app';
.lawsuits-contract-list {
  margin-top: 32px;

  > .title {
    @include typography-label;
    color: $color-neutral-600;
  }

  > .list-item + .list-item,
  > * + * {
    margin-top: 8px;
  }

  > .message {
    @include typography-label;
    text-align: right;
    margin-top: 8px;
  }

  &.-error {
    color: $color-negative-600;

    > .title {
      color: $color-negative-600;
    }
  }
}
