@import 'styles/app';

.title {
  @include typography-card-sub-title;
  color: $color-neutral-700;
}

.totalSum {
  float: right;
  color: $color-neutral-900;
}

.buttonWrapper {
  display: flex;
  gap: 16px;
  justify-content: center;
}
