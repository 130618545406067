@import 'styles/app';

.cityRow {
  background: white;
}

.cityRow:hover {
  background-color: $color-accent-50;
}

.cityCell {
  cursor: pointer;
}

.cityCellIcon {
  display: flex;
  align-items: center;
  padding: 0 24px;
}

.cityCellIconOpen {
  transform: rotate(90deg);
  transition: 0.3s ease-in-out;
  margin-right: 8px;
}

.cityCellIconClose {
  transition: 0.3s ease-in-out;
  margin-right: 8px;
}

.locationQuantityCell {
  font-weight: bold;
}

.loadMoreOrigins {
  letter-spacing: 1.5px;
}
