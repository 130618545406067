@import 'styles/app';
@import 'styles/mixins/button';

.link-default {
  @include hide-on-print;

  display: inline-flex;
  align-items: center;
  color: $color-neutral-600;
  font-family: $font-default;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 10%;
  cursor: pointer;
  font-weight: 600;

  &:not(.-as-button) {
    &:hover {
      text-decoration: none;
      color: $color-neutral-800;
      > * {
        text-decoration: underline;
      }

      > .icon > path {
        fill: $color-neutral-800;
      }
    }

    > .icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    > .icon > path {
      fill: $color-neutral-600;
    }

    &.-highlight {
      color: $color-accent-600;
      > .icon > path {
        fill: $color-accent-600;
      }
      &:hover {
        color: $color-accent-800;
        > .icon > path {
          fill: $color-accent-600;
        }
      }
    }

    &.-disabled,
    &.-disabled:hover {
      cursor: text;
      color: $color-neutral-600;
    }

    &.-underline {
      text-decoration: underline;
    }

    & > .button-default:hover {
      text-decoration: none;
    }
  }

  &.-as-button {
    @include button-styles;

    &.-social {
      > .icon {
        margin-right: 2px;
      }
    }
  }
}
