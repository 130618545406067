@import 'styles/app';

.description {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
}

.createdBy {
  font-size: 12px;
  line-height: 16px;
  color: $color-neutral-600;
  margin-top: 8px;
}

.actions {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}

.eventBadge {
  @include typography-card-sub-title;
  color: $color-neutral-700;
  font-size: 10px;
  display: inline-flex;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
