@import 'styles/app';

.card {
  padding: 16px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.05em;
  color: $color-neutral-700;
}

.images {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
  width: 100%;
}

.actions {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.input {
  display: none;
}

button {
  padding: 8px 0;
}

.inputTrigger {
  cursor: pointer;
  font-weight: 600;
  padding: 8px 16px;
}
