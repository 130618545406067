@import 'styles/app';

.executionStatus {
  align-items: center;
  color: $color-neutral-700;
  display: flex;
  font-size: 12px;
  gap: 4px;
  line-height: 16px;
}

.executionStatus + .executionStatus {
  margin-top: 8px;
}

.bold {
  font-weight: 700;
}

@media print {
  .executionStatus {
    margin: 0;
  }

  .eventBadge,
  .degreeBadge {
    @include typography-small;
    font-weight: 600;
    border: 1px solid $color-neutral-100;
    height: fit-content;
    background-color: transparent;
  }

  .lawsuitLink {
    display: block;
    pointer-events: none;
    text-decoration: none !important;
    color: $color-neutral-700 !important;
  }
}
