@import 'styles/app';

.info {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: block;
  margin-top: 8px;
  color: $color-accent-700;
}
