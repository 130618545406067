@import 'styles/app';

.reset {
  border-radius: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.toast {
  @include hide-on-print;
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  font-family: $font-default;
  font-size: 18px;
  padding: 16px;
  line-height: 24px;
}

.success {
  background: $color-positive-50;
  border: 1px solid $color-positive-700;
  color: $color-positive-700;
}

.error {
  background: $color-negative-50;
  border: 1px solid $color-negative-700;
  color: $color-negative-700;
}

.info {
  background: $color-accent-50;
  border: 1px solid $color-accent-700;
  color: $color-accent-700;
}

.toastDefault {
  background: rgba($color-neutral-500, 0.1);
  border: 1px solid $color-neutral-700;
  color: $color-neutral-700;
}

.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message {
  margin-left: 16px;
  margin-top: 0;
}
