@import 'styles/app';

.logoImage {
  margin-top: 20px;
}

.logo {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;

  margin-left: -6px;
}

.logoOpen {
  justify-content: space-between;
  flex-direction: row;

  margin-top: 0px;
  margin-left: 0px;

  & > svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .logoImage {
    margin-top: 0px;
  }
}
