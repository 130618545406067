@import 'styles/app';

.lawsuitsIndex {
  @include container-width;

  > .addProcessButton {
    display: grid;
    place-items: center;
  }

  > .warning {
    font-family: $font-default;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: $color-neutral-600;
  }
}
