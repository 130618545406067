@import 'styles/app';

.title {
  margin-bottom: 32px;
}

.body {
  @include container-width($fullWidth: true);
}

.information {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col {
  flex-basis: 50%;
}

.fullWidthCol {
  flex-basis: 100%;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 30px 0px 30px 0px;
  }

  .main {
    background-color: white;
    max-width: 100%;
    width: 100%;
    padding-bottom: 0px;
  }

  .main > * {
    max-width: 100%;
    width: 100%;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 32px;
    margin-top: 0px;
    color: $color-neutral-700;
    margin-bottom: 16px;
  }

  .information {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }

  .col {
    flex-basis: 100%;
  }
}
