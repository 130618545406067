@import 'styles/app';

.errorTitle {
  @include typography-card-sub-title;
  color: $color-neutral-700;
}

@media print {
  .errorWrapper {
    @include hide-on-print;
  }
}
