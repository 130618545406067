.modal {
  width: 640px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.titleBold {
  font-weight: 900;
  margin-right: 8px;
}

.body {
  height: 240px;
  overflow: auto;
}

.link {
  display: inline-block;

  &::first-letter {
    text-transform: capitalize;
  }
}

.item {
  display: flex;
}

.item:not(:first-of-type) {
  margin-top: 24px;
}

.footer {
  margin-top: 32px;
}

.badge {
  display: inline-flex;
}

.content {
  height: 310px;
}
