@import 'styles/app';

.link {
  cursor: pointer;
  text-decoration: underline;
}

.status {
  font-size: 16px;
}

.content {
  margin-top: 0;
}

.title {
  font-size: 20px;
  font-weight: 500;
  color: $color-neutral-700;
}

.provider {
  color: $color-accent-600;
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.failed {
  color: $color-rouge-600;
}

.strong {
  font-weight: 700;
}

.message {
  font-size: 12px;
  font-weight: 500;
  color: $color-neutral-400;
  display: flex;
  align-items: center;
}
